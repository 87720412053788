import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { ApiService, BroadcastService, Dictionary, StorageService } from '@ai/ngx-concentric';
import { CoreDrawerComponent } from '../../../../../@core/components/drawer';
import { ReadingsService } from '../readings/readings.service';
import { AlertService } from '../../../../core/services/alert.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Unit } from '@ai/ngx-concentric/lib/models/unit';
import { ChannelPipe } from './channel-settings.pipe';
import { ChannelSettingsService } from './channel-settings.service';
import { AnalogReading, DigitalReading, PendingAnalogReading, PendingDigitalReading } from '../readings/readings.model';
import { DataPointEnum } from '../../../../core/enums/data-point.enum';
import { UnitDataPoint } from '../../../../core/models/unit-data-point.model';
import * as _ from 'lodash';
import { ChannelTypeEnum } from '../../../../core/enums/channel-type.enum';
import { BwctHubService } from '../../../../core/services/bwct-hub.service';
import { UnitService } from '../../../../core/services/unit.service';
import { environment } from '../../../../../environments/environment';
import { ChannelDisabledResponse, ChannelSetting, PcsLabel } from './channel-settings.models';
import { FormService } from '../../../../core/services/form.service';
import { InputTypeEnum } from '../../../../core/enums/input-type.enum';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AnalogChannelModel, DigitalChannelModel, RM5AnalogChannels, RM5DigitalChannels } from '../readings/components/channel/channel.models';
import { MatTooltip } from '@angular/material/tooltip';
import { UnitStyleEnum } from 'app/core/enums/unit-style.enum';
import { ActivatedRoute } from '@angular/router';
import { BrandType } from 'app/core/enums/brand-type.enum';

@Component({
    selector: 'channel-settings',
    templateUrl: './channel-settings.component.html',
    styleUrls: ['channel-settings.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChannelSettingsComponent implements OnInit, OnDestroy {
    @ViewChild('settingsDrawer') settingsDrawer: CoreDrawerComponent;
    @ViewChild('auto') auto;

    @ViewChild('batteryAlarmTooltip') batteryAlarmTooltip: MatTooltip;

    @Input() unit: Unit;
    @Input() unitDataPoint: UnitDataPoint;
    @Input() reportedAnalogReading: AnalogReading;
    @Input() reportedDigitalReading: DigitalReading;
    @Input() electricalCurrent: string;
    @Input() isAnalog: boolean = true;

    public static broadcastReadingsEvent:BehaviorSubject <any>;
    public pendingAnalogReading: PendingAnalogReading;
    public pendingDigitalReading: PendingDigitalReading;
    public form: FormGroup;
    public payloadForm: FormGroup;
    public channelIndex: number;
    public instanceId: number;
    public inputType: number = 0;
    public maxMin = 0;
    public unitMin = -10000;
    public unitMax = 10000;
    public isSaving: boolean = false;
    public pcsLabels: PcsLabel[] = [];
    public resetModes: Dictionary<number, string>[] = [{ key: 0, value: 'No Reset' }, { key: 1, value: 'Reset On Count' }];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _dataPointEnum = DataPointEnum;
    private accumulatorUnitDataPoint: UnitDataPoint;
    private analogChannelConfiguration: AnalogChannelModel;
    private digitalChannelConfiguration: DigitalChannelModel;
    private readonly AC_CURRENT: string = 'ac';
    private readonly DC_CURRENT: string = 'dc';
    unitId: number;
    public disabledVolts: boolean = false;

    constructor(private _alertService: AlertService,
        private _apiService: ApiService,
        private _broadcastService: BroadcastService,
        private _bwctHubService: BwctHubService,
        private _channelPipe: ChannelPipe,
        public _channelSettingsService: ChannelSettingsService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        public _formService: FormService,
        private _storageService: StorageService,
        private _readingsService: ReadingsService,
        private _unitService: UnitService,
        private _activatedRoute: ActivatedRoute) {
        _storageService.watch().subscribe((key) => {
            switch (key) {
                case "selectedUnitId":
                    this.unitId = +_storageService.get(key);
                    break;
            }
        });
    }

    // -----------------------------------------------------------------
    // ngOnInit
    // -----------------------------------------------------------------
    ngOnInit(): void {
        this.subscribeToBroadcast();           
    }
    // -----------------------------------------------------------------
    // Checks for Cannel Voltage Limits
    // -----------------------------------------------------------------
    checkChannelVoltageLimits(): void {
        if (this.electricalCurrent != null) {
            if (this.electricalCurrent === this.AC_CURRENT) {
                this.unitMin = -20;
                this.unitMax = 20;
            }
            if (this.electricalCurrent === this.DC_CURRENT) {
                this.unitMin = -30;
                this.unitMax = 30;
            }
    
            if (this.form?.controls[`${this.electricalCurrent}_input_type`].value === 2) {
                this.unitMax *= 1000;
                this.unitMin *= 1000;
            }
    
            if (!!this.unit && this.unit != null && !isNaN(this.unit?.brandId)) {
                let analogChannelConfiguration = RM5AnalogChannels.get(this.unit.brandId)[this.channelIndex - 1];

                // get the current scale based on input type (v or mV)
                let analogChannelConfigurationScalling = analogChannelConfiguration.channelScalling[+this.reportedAnalogReading.scratchpad_2[`${this.electricalCurrent}_input_type`] - 1];
                analogChannelConfigurationScalling.scale_min_from = this.unitMin;
                analogChannelConfigurationScalling.scale_max_from = this.unitMax;        
                analogChannelConfiguration.channelScalling[this.form.controls[this.electricalCurrent + '_input_type'].value-1].scale_min_from = this.unitMin
                analogChannelConfiguration.channelScalling[this.form.controls[this.electricalCurrent + '_input_type'].value-1].scale_max_from = this.unitMax
            }
        }
    }

    // -----------------------------------------------------------------
    // Sets Channel Voltage Min and Max depending on the Unit Brand Id
    // -----------------------------------------------------------------
    async setChanneVoltagelMinMaxInputValues(): Promise<void> {
        this.unitId = this._unitService.getUnitId();
        var unit = await this._unitService.getUnitByIdAsync(this.unitId);
        switch (unit.brandId) {
            case 68:
            case 69:
            case 72:
            case 73:
                this.checkChannelVoltageLimits();
                break;
        }
    }

    // -----------------------------------------------------------------
    // Increments the provided form value
    // -----------------------------------------------------------------
    increment(formControlName: string, maxLimit: number, fixedBy: number): void {
        this.form = this._formService.increment(this.form, formControlName, maxLimit, 1, fixedBy);
    }

    // -----------------------------------------------------------------
    // Decrements the provided form value
    // -----------------------------------------------------------------
    decrement(formControlName: string, minLimit: number, fixedBy: number): void {
        this.form = this._formService.decrement(this.form, formControlName, minLimit, 1, fixedBy);
    }

    // -----------------------------------------------------------------
    // Loop through the form and created a new form for the controls that was changed and check if form is valid else return null
    // -----------------------------------------------------------------
    createFormControls(forms: FormGroup[]) {
        this.payloadForm = this._formBuilder.group({});
        forms.forEach((form) => {
            Object.keys(form.controls).forEach((key) => {
                form.controls[key].markAsTouched();
                if (form.controls[key].dirty || !form.controls[key].pristine) {
                    this.payloadForm.addControl(key, form.controls[key]);
                }
                if (!form.controls[key].valid) {
                    this.isSaving = false;
                    return null;
                }
            });
        });
    }

    // -----------------------------------------------------------------
    // Saves the form
    // -----------------------------------------------------------------
    async save(): Promise<void> {
        // Build payload form based on dirty controls
        this.isSaving = true;
        this.createFormControls([this.form]);
        this.validateAlarmSettings();
        if (this.form.invalid) {
            this.isSaving = false;
            return;
        }

        // If no changes were made
        if (Object.keys(this.payloadForm.controls).length === 0) {
            this.settingsDrawer.close();
            this._alertService.success('Your channel settings have been updated');
            this.isSaving = false;
            return;
        }

        // patch unit data point
        await this.handleUnitDataPointPatchAsync(this.payloadForm);

        // Create request
        let payloadRequest;
        let dataPointId;
        const isEnabled = this.form.controls[this.electricalCurrent === 'ac' ? 'ac_enabled' : 'dc_enabled']?.value ?? this.form.controls['enabled']?.value ?? false;
        if (this.isAnalog) {
            // Send the measurements back to the readings component
            this._readingsService.setMeasurements({
                type: ChannelTypeEnum.analog,
                channel: this.reportedAnalogReading.channel_1,
                measurement: this.electricalCurrent,
                isEnabled: isEnabled,
                reading: this.reportedAnalogReading
            });
            // Build the analog payload
            const payloadResponse = await this._channelSettingsService.buildAnalogJsonPayloadAsync(this.form, this.payloadForm, this.reportedAnalogReading, this.unit, this.electricalCurrent, this.instanceId);
            payloadRequest = JSON.stringify(payloadResponse.payloadRequest);
            this.unit = payloadResponse.unit;
            dataPointId = this.electricalCurrent === 'ac' ? this.analogChannelConfiguration.analogACDataPointId : this.analogChannelConfiguration.analogDCDataPointId;
        } else {
            // Send the measurements back to the readings component
            this._readingsService.setMeasurements({
                type: ChannelTypeEnum.digital,
                channel: this.reportedDigitalReading.channel_1,
                measurement: null,
                isEnabled: this.form.controls.enabled.value,
                reading: this.reportedDigitalReading
            });
            // Build the digital payload
            const payloadResponse = await this._channelSettingsService.buildDigitalJsonPayloadAsync(this.form, this.payloadForm, this.reportedDigitalReading, this.unit, this.instanceId);
            payloadRequest = JSON.stringify(payloadResponse.payloadRequest);
            this.unit = payloadResponse.unit;
            dataPointId = this.digitalChannelConfiguration.digitalInputDataPointId;
        }

        // Channel disabled
        if (!isEnabled) {
            this._channelSettingsService.channelDisabled = {
                isAnalog: this.isAnalog,
                dataPointId: dataPointId
            };
        }

        // Submit request
        await this._apiService.putAsync(`${environment.bullhornApiUrl}/Ota/SendOtaRequestAsync`, {
            deviceId: this.unit.streamId,
            request: payloadRequest,
        }).then(async () => {
            this.isSaving = false;
            this._alertService.success('Your channel settings have been updated');
            this.settingsDrawer.close();
            this._broadcastService.broadcast('readings:update-channel-settings', {
                unit: this.unit
            });
        }).catch(() => {
            this.isSaving = false;
        });
    }

    // -----------------------------------------------------------------
    // cancels the user input and closes the drawer
    // -----------------------------------------------------------------
    cancel(): void {
        this._broadcastService.broadcast('readings:close-drawer');
    }

    // -----------------------------------------------------------------
    // Builds the form
    // -----------------------------------------------------------------
    buildForm(): void {
        this.form = this._formBuilder.group({});
    }

    // -----------------------------------------------------------------
    // Remove any residual updates from toggling from ac/dc
    // -----------------------------------------------------------------
    clearTheForm(): void {
        this.unit = null;
        this.electricalCurrent = null;
        this.reportedAnalogReading = null;
        this.unitDataPoint = null;
        this.form = null;
        this.form = this._formBuilder.group({});
    }

    // -----------------------------------------------------------------
    // Populates the form either by showing the drawer or when its an MFE
    // -----------------------------------------------------------------
    async populateAnalogFormAsync(unit: Unit, unitDataPoint: UnitDataPoint, measurement: string, analogReading: AnalogReading, pendingAnalogReading: PendingAnalogReading): Promise<void> {
        this.clearTheForm();
        this.unit = unit as Unit;
        this.electricalCurrent = measurement;
        this.reportedAnalogReading = analogReading;
        this.pendingAnalogReading = pendingAnalogReading;
        this.unitDataPoint = unitDataPoint;
        this.form = this._channelSettingsService.updateAnalogForm(this.form, unit, this.reportedAnalogReading, this.unitDataPoint, this.electricalCurrent);
        this.subscribeToAnalogChannelFormChanges();
        // Check alarm settings
        setTimeout(() => {
            this.validateAlarmSettings();
        }, 100);
    }

    // -----------------------------------------------------------------
    // Populates the form either by showing the drawer or when its an MFE
    // -----------------------------------------------------------------
    async populateDigitalFormAsync(unit: Unit, unitDataPoint: UnitDataPoint, accumulatorUnitDataPoint: UnitDataPoint, measurement: string,
        digitalReading: DigitalReading, pendingDigitalReading: PendingDigitalReading): Promise<void> {
        this.clearTheForm();
        this.unit = unit as Unit;
        this.electricalCurrent = measurement;
        this.reportedDigitalReading = digitalReading;
        this.pendingDigitalReading = pendingDigitalReading;
        this.unitDataPoint = unitDataPoint;
        this.accumulatorUnitDataPoint = accumulatorUnitDataPoint;
        this.form = this._channelSettingsService.updateDigitalForm(this.form, this.reportedDigitalReading, this.unitDataPoint, accumulatorUnitDataPoint);
        this.subscribeToDigitalChannelFormChanges();
    }

    // -----------------------------------------------------------------
    // Handles the patch Unit Data Point request
    // -----------------------------------------------------------------
    async handleUnitDataPointPatchAsync(payloadForm: FormGroup): Promise<void> {
        const payload = [];
        // Patch Channel Settings
        if (payloadForm.controls?.['data_point_name']?.value) {
            payload.push({
                'path': '/DataPointName',
                'op': 'replace',
                'value': payloadForm.controls?.['data_point_name']?.value
            });
        }
        if (payloadForm.controls?.['display_unit_label']?.value) {
            payload.push({
                'path': '/DisplayUnitLabel',
                'op': 'replace',
                'value': payloadForm.controls?.['display_unit_label']?.value
            });
        }
        if (payloadForm.controls?.[`${this.electricalCurrent}_enabled`]?.value !== undefined || payloadForm.controls?.['enabled']?.value !== undefined) {
            payload.push({
                'path': '/Enabled',
                'op': 'replace',
                'value': this.isAnalog ? payloadForm.controls?.[`${this.electricalCurrent}_enabled`]?.value : payloadForm.controls?.['enabled']?.value
            });
        }
        if (payloadForm.controls?.[`${this.electricalCurrent}_decimal_places`]?.value && payloadForm.controls?.[`${this.electricalCurrent}_decimal_places`]?.dirty) {
            payload.push({
                'path': '/ConversionTemplate',
                'op': 'replace',
                'value': this._channelSettingsService.updateConversionTemplate(this.unitDataPoint, payloadForm.controls?.[`${this.electricalCurrent}_decimal_places`]?.value)
            });
        }

        if (payload.length > 0) {
            if (this.unit?.brand.unitStyleId == UnitStyleEnum.SingleUnit) {
                await this._readingsService.patchUnitDataPointAsync(payload, this.unit.unitId, this.unitDataPoint.dataPointId).catch(() => {
                    this.isSaving = false;
                });
            }
            else {
                await this._readingsService.PatchChildUnitDataPointAsync(payload, this.unit.unitId, this.unitDataPoint.dataPointId, this.instanceId).catch(() => {
                    this.isSaving = false;
                });
            }
        }
        // Patch DataPoints for Digital Accumulator
        if (payloadForm.controls?.['accumulator_enable_7']?.value !== undefined) {
            payload.push({
                'path': '/Enabled',
                'op': 'replace',
                'value': +payloadForm.controls?.['accumulator_enable_7']?.value
            });
            const datapointId = this.channelIndex === 1 ? 1655 : 1656;

            if (this.unit?.brand.unitStyleId == UnitStyleEnum.SingleUnit) {
                await this._readingsService.patchUnitDataPointAsync(payload, this.unit.unitId, datapointId).catch(() => {
                    this.isSaving = false;
                });
            }
            else {
                await this._readingsService.PatchChildUnitDataPointAsync(payload, this.unit.unitId, datapointId, this.instanceId).catch(() => {
                    this.isSaving = false;
                });
            }
        }
    }

    // -----------------------------------------------------------------
    // Subscribe To Broadcast events
    // -----------------------------------------------------------------
    subscribeToBroadcast(): void {
        // -------------------------------------------------------------------------------
        // Triggered whenever a measurement is selected from the readings component
        // -------------------------------------------------------------------------------
        this._broadcastService.subscribe('readings:show-channel-settings', async (data) => {
            this.disabledVolts = false;
            this.pcsLabels = data.pcsLabels;
            this.settingsDrawer.toggle();
            this.unit = data.unit;
            this.unitDataPoint = data.unitDataPoint;
            this.accumulatorUnitDataPoint = data.accumulatorUnitDataPoint;
            this.channelIndex = data.channelIndex;
            this.instanceId = data.instanceId;
            if (data.isAnalog) {
                this.isAnalog = true;
                this.analogChannelConfiguration = RM5AnalogChannels.get(this.unit.brandId)[this.channelIndex - 1];
                await this.populateAnalogFormAsync(data.unit as Unit, data.unitDataPoint, data.measurement, data.reading['analogReading'] as AnalogReading, data.reading['pendingAnalogReading'] as PendingAnalogReading);                
                if ((data.unit?.brandId == BrandType.RM520C || data.unit?.brandId == BrandType.RM520S) && 
                    data.measurement === 'dc' && data.reading.analogReading.name_0 === 'Channel 3') {
                    this.form?.controls?.['dc_input_type']?.setValue(2);
                    this.disabledVolts = true;
                }
                return;
            }
            else {
                this.digitalChannelConfiguration = RM5DigitalChannels.get(this.unit.brandId)[this.channelIndex - 1];
            }
            this.isAnalog = false;
            await this.populateDigitalFormAsync(data.unit as Unit, data.unitDataPoint, data.accumulatorUnitDataPoint, data.measurement, data.reading['digitalReading'] as DigitalReading, data.reading['pendingDigitalReading'] as PendingDigitalReading);
        });

        ChannelSettingsComponent.broadcastReadingsEvent.subscribe(async (data) => {
            this.electricalCurrent == data.measurement;
            this.setChanneVoltagelMinMaxInputValues();
        });

        // -------------------------------------------------------------------------------
        // Triggered whenever Bullhorn API receives UpdateUnitSettingsAsync
        // -------------------------------------------------------------------------------
        this._bwctHubService.unit$.pipe(takeUntil(this._unsubscribeAll)).subscribe((unit: Unit) => {
            this.unit = unit;
        });
    }

    // -----------------------------------------------------------------
    // Subscribe To Analog Channel Form Changes
    // -----------------------------------------------------------------
    subscribeToAnalogChannelFormChanges(): void {
        // Listen to the enabled toggle and update the form controls
        this.listenToEnableToggle();

        // Listen to the input toggle and updates the form controls
        this.listenToInputToggle();

        // Listen to engineering units label
        this.listenToEngineeringUnitsLabel();

        // Listen to max/min alarm toggle form controls
        this.listenToAnalogAlarmToggle();

        // Listen to max/min alarm threshold and deadband form controls
        this.listenToAlarmSettings();

        // Listen to max/min alarm persistence form controls
        this.listenToAnalogAlarmPersistence();

        // No need to listen to other form controls as the rest are for direct current channels
        if (this.electricalCurrent === 'ac') {
            return;
        }

        // Checks if scale max from is greater than scale max to
        this.listenToScaleMaxFrom();

        // Checks if scale max to is less than scale max from
        this.listenToScaleMaxTo();

        // Checks if scale min from is greater than scale min to
        this.listenToScaleMinFrom();

        // Checks if scale min to is less than scale min from
        this.listenToScaleMinTo();

        // Creates or destroys scale max/min from/to form controls
        this.listenToShuntToggle();

        // Checks the high alarm and low alarm thresholds based on the amps scaled
        this.listenToAmpsScaled();

        // Checks the millivolts input value
        this.listenToMillivolts();

        // Checks the edge offset toggle value
        // this.listenEdgeOffsetToggle(); TODO: Enable when FW sets up the OMA_XML

        // Checks the edge offset input value
        this.listenEdgeOffset();
    }

    // -----------------------------------------------------------------
    // Subscribe To Digital Channel Form Changes
    // -----------------------------------------------------------------
    subscribeToDigitalChannelFormChanges(): void {
        // Listen to the enabled toggle and update the form controls
        this.listenToEnableToggle();

        // Listen to max/min alarm persistence form controls
        this.listenToDigitalAlarmPersistence();

        // Listen to accumulator enabled
        this.listenToDigitalAccumulatorToggle();

        // Listen to accumulator alarm enabled
        this.listenToDigitalAccumulatorAlarmToggle();

        // Listen to reset mode
        this.listenToDigitalAccumulatorResetMode();
    }

    // -----------------------------------------------------------------
    // listens to the enable Toggle
    // -----------------------------------------------------------------
    listenToEnableToggle(): void {
        if (this.isAnalog) {
            this.form.get(`${this.electricalCurrent}_enabled`).valueChanges.subscribe(async (value) => {
                if (!value) {
                    this.unitDataPoint.enabled = false;
                    _.forEach(this._channelSettingsService.getAllFormControls(true), (controlName) => {
                        this.form.removeControl(controlName);
                    });
                } else {
                    this.unitDataPoint.enabled = true;
                    await this.populateAnalogFormAsync(this.unit, this.unitDataPoint, this.electricalCurrent, this.reportedAnalogReading, this.pendingAnalogReading);
                    this.form.controls[`${this.electricalCurrent}_enabled`].markAsDirty();
                }
            });
        } else {
            this.form.get('enabled').valueChanges.subscribe(async (value) => {
                if (!value) {
                    this.unitDataPoint.enabled = false;
                    _.forEach(this._channelSettingsService.getAllFormControls(false), (controlName) => {
                        this.form.removeControl(controlName);
                    });
                } else {
                    this.unitDataPoint.enabled = true;
                    await this.populateDigitalFormAsync(this.unit, this.unitDataPoint, this.accumulatorUnitDataPoint, this.electricalCurrent, this.reportedDigitalReading, this.pendingDigitalReading);
                    this.form.controls.enabled.markAsDirty();
                }
            });
        }
    }

    // -----------------------------------------------------------------
    // listens to the Digital Accumulator Toggle
    // -----------------------------------------------------------------
    listenToDigitalAccumulatorToggle(): void {
        this.form.get('accumulator_enable_7').valueChanges.subscribe((value) => {
            if (!value) {
                ['count_10', 'count_alarm_enable_58', 'count_alarm_value_57', 'reset_mode_11', 'reset_at_12', 'reset_to_13'].forEach((key) => {
                    this.form.removeControl(key);
                });
            }
            if (this.form.controls?.accumulator_enable_7.value === true || this.form.controls?.accumulator_enable_7.value === 1) {
                this.form.addControl('count_10', new FormControl(this.reportedDigitalReading?.count_10 ?? 10, [Validators.min(0), Validators.max(999999999), Validators.required]));
                this.form.addControl('count_alarm_enable_58', new FormControl(this.reportedDigitalReading?.count_alarm_enable_58 ?? false, Validators.required));

                this.listenToDigitalAccumulatorAlarmToggle();
                if (this.form.controls?.count_alarm_enable_58?.value === true || this.form.controls?.count_alarm_enable_58?.value === 1) {
                    this.form.addControl('count_alarm_value_57', new FormControl(this.reportedDigitalReading?.count_alarm_value_57 ?? 100, [Validators.min(0), Validators.max(999999999), Validators.required]));
                }

                this.form.addControl('reset_mode_11', new FormControl(this.reportedDigitalReading?.reset_mode_11 ?? 0, [Validators.min(0), Validators.max(999999999), Validators.required]));
                this.listenToDigitalAccumulatorResetMode();
                if (this.form.controls?.reset_mode_11?.value === 1) {
                    this.form.addControl('reset_at_12', new FormControl(this.reportedDigitalReading?.reset_at_12 ?? 100, [Validators.min(0), Validators.max(999999999), Validators.required]));
                    this.form.addControl('reset_to_13', new FormControl(this.reportedDigitalReading?.reset_to_13 ?? 0, [Validators.min(0), Validators.max(999999999), Validators.required]));
                }
            }
        });
    }

    // -----------------------------------------------------------------
    // listens to the Digital Accumulator Alarm Toggle
    // -----------------------------------------------------------------
    listenToDigitalAccumulatorAlarmToggle(): void {
        if (this.form.controls?.count_alarm_enable_58?.value === undefined) {
            return;
        }
        this.form.get('count_alarm_enable_58').valueChanges.subscribe((value) => {
            if (!value) {
                ['count_alarm_value_57'].forEach((key) => {
                    this.form.removeControl(key);
                });
            }
            if (this.form.controls?.count_alarm_enable_58.value == true || this.form.controls?.count_alarm_enable_58.value == 1) {
                this.form.addControl('count_alarm_value_57', new FormControl(this.reportedDigitalReading?.count_alarm_value_57 ?? 100, [Validators.min(0), Validators.max(999999999), Validators.required]));
            }
        });
    }

    // -----------------------------------------------------------------
    // listens to the Digital Accumulator Reset Mode
    // -----------------------------------------------------------------
    listenToDigitalAccumulatorResetMode(): void {
        if (this.form.controls?.reset_mode_11?.value === undefined) {
            return;
        }
        this.form.get('reset_mode_11').valueChanges.subscribe((value) => {
            if (value === 0) {
                ['reset_at_12', 'reset_to_13'].forEach((key) => {
                    this.form.removeControl(key);
                });
            }
            if (this.form.controls?.reset_mode_11.value === 1) {
                this.form.addControl('reset_at_12', new FormControl(this.reportedDigitalReading?.reset_at_12 ?? 100, [Validators.min(0), Validators.max(999999999), Validators.required]));
                this.form.addControl('reset_to_13', new FormControl(this.reportedDigitalReading?.reset_to_13 ?? 0, [Validators.min(0), Validators.max(999999999), Validators.required]));
            }
        });
    }

    // -----------------------------------------------------------------
    // listens to the input Toggle
    // -----------------------------------------------------------------
    listenToInputToggle(): void {
        this.form.get(`${this.electricalCurrent}_input_type`).valueChanges.subscribe((value) => {
            this.reportedAnalogReading.scratchpad_2[`${this.electricalCurrent}_input_type`] = value;
            if (!this.analogChannelConfiguration.multipleScalling || value === 1) {
                this.addScaleMaxMinControls();
                this.listenToScaleMaxFrom();
                this.listenToScaleMaxTo();
                this.listenToScaleMinFrom();
                this.listenToScaleMinTo();
                this.removeVoltsAndAmpsControls();
                this.validateAlarmSettings();
            }
            if (this.analogChannelConfiguration.multipleScalling && value === 2) {
                this.addVoltsAndAmpsControls();
                this.removeScaleMaxMinControls();
                this.listenToAmpsScaled();
                this.listenToMillivolts();
                this.validateAlarmSettings();
            }
            this.inputType = value;
            this.setChanneVoltagelMinMaxInputValues();
        });
    }

    // -----------------------------------------------------------------
    // listens to Shunt Toggle
    // -----------------------------------------------------------------
    listenToShuntToggle(isVisible: boolean = null): void {
        this.form.get('shunt_enabled').valueChanges.subscribe((value) => {
            const boolValue = isVisible ?? value;
            if (boolValue && this.electricalCurrent === 'dc' && (!this.analogChannelConfiguration.multipleScalling || +this.reportedAnalogReading.scratchpad_2[`${this.electricalCurrent}_input_type`] === 1)) {
                this.addScaleMaxMinControls();
                this.listenToScaleMaxFrom();
                this.listenToScaleMaxTo();
                this.listenToScaleMinFrom();
                this.listenToScaleMinTo();
            }
            if (!boolValue && this.electricalCurrent === 'dc' && (!this.analogChannelConfiguration.multipleScalling || +this.reportedAnalogReading.scratchpad_2[`${this.electricalCurrent}_input_type`] === 1)) {
                this.removeScaleMaxMinControls();
            }
            if (boolValue && this.electricalCurrent === 'dc' && (this.analogChannelConfiguration.multipleScalling && +this.reportedAnalogReading.scratchpad_2[`${this.electricalCurrent}_input_type`] === 2)) {
                this.form.addControl('shunt_volt_input', new FormControl(this.reportedAnalogReading.scratchpad_2?.shunt_volt_input ?? 50, [Validators.min(0), Validators.max(10000), Validators.required]));
                this.form.addControl('shunt_amps_scaled', new FormControl(this.reportedAnalogReading.scratchpad_2?.shunt_amps_scaled ?? 100, [Validators.min(0), Validators.max(1000), Validators.required]));
            }
            if (!boolValue && this.electricalCurrent === 'dc' && (this.analogChannelConfiguration.multipleScalling && +this.reportedAnalogReading.scratchpad_2[`${this.electricalCurrent}_input_type`] === 2)) {
                this.form.removeControl('shunt_volt_input');
                this.form.removeControl('shunt_amps_scaled');
            }
            this.validateAlarmSettings();
        });
    }

    // -----------------------------------------------------------------
    // listens to the input Toggle
    // -----------------------------------------------------------------
    listenToMillivolts(): void {
        if (!this.form.get('shunt_volt_input')) {
            return;
        }
        this.form.get('shunt_volt_input').valueChanges.subscribe((value) => {
            if (isNaN(value)) {
                this.form.controls['shunt_volt_input'].setErrors({ required: true });
                this.form.controls['shunt_volt_input'].markAsTouched();
                return;
            }
            this.validateAlarmSettings();
        });
    }


    // -----------------------------------------------------------------
    // listens to the input Toggle
    // -----------------------------------------------------------------
    listenEdgeOffsetToggle(): void {
        this.form.get('instant_off_enable_83').valueChanges.subscribe((value) => {
            if (value) {
                this.form.addControl('instant_off_edge_offset_84', new FormControl(this.reportedAnalogReading?.instant_off_edge_offset_84 ?? 200, Validators.required));
                this.listenEdgeOffset();
            }
            if (!value) {
                this.form.removeControl('instant_off_edge_offset_84');
            }
        });
    }


    // -----------------------------------------------------------------
    // listens to the edge offset
    // -----------------------------------------------------------------
    listenEdgeOffset(): void {
        if (!this.form.get('instant_off_edge_offset_84')) {
            return;
        }
        this.form.get('instant_off_edge_offset_84').valueChanges.subscribe((value) => {
            if (isNaN(value) || value === '') {
                this.form.controls['instant_off_edge_offset_84'].setErrors({ valueRequired: 'A value is required' });
                this.form.controls['instant_off_edge_offset_84'].markAsTouched();
                return;
            }
            if (+this.form.controls['instant_off_edge_offset_84'].value < 0 || +this.form.controls['instant_off_edge_offset_84'].value > 60000) {
                this.form.controls['instant_off_edge_offset_84'].setErrors({ minMaxExceeded: 'The value must be between 0 and 60,000 milliseconds' });
                this.form.controls['instant_off_edge_offset_84'].markAsTouched();
            } else {
                this.form.controls['instant_off_edge_offset_84'].setErrors(null);
                this.form.controls['instant_off_edge_offset_84'].markAsTouched();
            }
        });
    }


    // -----------------------------------------------------------------
    // listens to the input Toggle
    // -----------------------------------------------------------------
    listenToAmpsScaled(): void {
        if (!this.form.get('shunt_amps_scaled')) {
            return;
        }
        this.form.get('shunt_amps_scaled').valueChanges.subscribe((value) => {
            if (isNaN(value)) {
                this.form.controls['shunt_amps_scaled'].setErrors({ required: true });
                this.form.controls['shunt_amps_scaled'].markAsTouched();
                return;
            }
            this.validateAlarmSettings();
        });
    }

    // -----------------------------------------------------------------
    // Adds Scale Max Min Controls
    // -----------------------------------------------------------------
    addScaleMaxMinControls(): void {

        // as we may have multiple scalings, we need to remove the previous control in order to ensure the Validators
        this.form.removeControl('scale_min_from');
        this.form.removeControl('scale_max_from');
        this.form.removeControl('scale_min_to');
        this.form.removeControl('scale_max_to');

        let analogChannelConfiguration = RM5AnalogChannels.get(this.unit.brandId)[this.channelIndex - 1];
        // get the current scale based on input type (v or mV)
        let analogChannelConfigurationScalling = analogChannelConfiguration.channelScalling[+this.reportedAnalogReading.scratchpad_2[`${this.electricalCurrent}_input_type`] - 1];

        this.form.addControl('scale_min_from', new FormControl(this.reportedAnalogReading.scratchpad_2?.scale_min_from ?? 0,
            [Validators.min(analogChannelConfigurationScalling.scale_min_from), Validators.max(analogChannelConfigurationScalling.scale_max_from), Validators.required]));
        this.form.addControl('scale_max_from', new FormControl(this.reportedAnalogReading.scratchpad_2?.scale_max_from ?? 100,
            [Validators.min(analogChannelConfigurationScalling.scale_min_from), Validators.max(analogChannelConfigurationScalling.scale_max_from), Validators.required]));

        this.form.addControl('scale_min_to', new FormControl(this.reportedAnalogReading.scratchpad_2?.scale_min_to ?? 0,
            [Validators.min(analogChannelConfigurationScalling.scale_min_to), Validators.max(analogChannelConfigurationScalling.scale_max_to), Validators.required]));
        this.form.addControl('scale_max_to', new FormControl(this.reportedAnalogReading.scratchpad_2?.scale_max_to ?? 100,
            [Validators.min(analogChannelConfigurationScalling.scale_min_to), Validators.max(analogChannelConfigurationScalling.scale_max_to), Validators.required]));
    }

    // -----------------------------------------------------------------
    // Adds the millivolts and amps controls
    // -----------------------------------------------------------------
    addVoltsAndAmpsControls(): void {
        this.form.addControl('shunt_volt_input', new FormControl(this.reportedAnalogReading.scratchpad_2?.shunt_volt_input ?? 50, [Validators.min(0), Validators.max(10000), Validators.required]));
        this.form.addControl('shunt_amps_scaled', new FormControl(this.reportedAnalogReading.scratchpad_2?.shunt_amps_scaled ?? 100, [Validators.min(0), Validators.max(1000), Validators.required]));
    }

    // -----------------------------------------------------------------
    // Removes Scale Max Min Controls
    // -----------------------------------------------------------------
    removeScaleMaxMinControls(): void {
        this.form.removeControl('scale_max_from');
        this.form.removeControl('scale_max_to');
        this.form.removeControl('scale_min_from');
        this.form.removeControl('scale_min_to');
    }

    // -----------------------------------------------------------------
    // Removes the millivolts and amps controls
    // -----------------------------------------------------------------
    removeVoltsAndAmpsControls(): void {
        this.form.removeControl('shunt_volt_input');
        this.form.removeControl('shunt_amps_scaled');
    }

    // -----------------------------------------------------------------
    // listens to ScaleMinFrom value changes
    // -----------------------------------------------------------------
    listenToScaleMinFrom(): void {
        if (!this.form.get('scale_min_from')) {
            return;
        }
        this.form.get('scale_min_from').valueChanges.subscribe((value) => {
            if (isNaN(value)) {
                this.form.controls['scale_min_from'].setErrors({ required: true });
                this.form.controls['scale_min_from'].markAsTouched();
                return;
            }
            if (+value >= +this.form.controls['scale_max_from'].value) {
                this.form.controls['scale_min_from'].setErrors({ tooBig: true });
                this.form.controls['scale_min_from'].markAsTouched();
            }
            if (+this.form.get('scale_max_from').value > +this.form.controls['scale_min_from'].value) {
                this.form.controls['scale_max_from'].setErrors(null);
                this.form.controls['scale_max_from'].markAsTouched();
            }
        });
        this.validateAlarmSettings();
    }

    // -----------------------------------------------------------------
    // listens to ScaleMaxFrom value changes
    // -----------------------------------------------------------------
    listenToScaleMaxFrom(): void {
        if (!this.form.get('scale_max_from')) {
            return;
        }
        this.form.get('scale_max_from').valueChanges.subscribe((newValue) => {
            if (isNaN(newValue)) {
                this.form.controls['scale_max_from'].setErrors({ required: true });
                this.form.controls['scale_max_from'].markAsTouched();
                return;
            }
            if (+newValue <= +this.form.controls['scale_min_from'].value) {
                this.form.controls['scale_max_from'].setErrors({ tooBig: true });
                this.form.controls['scale_max_from'].markAsTouched();
            }
            if (+this.form.get('scale_min_from').value < +this.form.controls['scale_max_from'].value) {
                this.form.controls['scale_min_from'].setErrors(null);
                this.form.controls['scale_min_from'].markAsTouched();
            }
        });
        this.validateAlarmSettings();
    }

    // -----------------------------------------------------------------
    // listens to ScaleMinTo value changes
    // -----------------------------------------------------------------
    listenToScaleMinTo(): void {
        if (!this.form.get('scale_min_to')) {
            return;
        }
        this.form.get('scale_min_to').valueChanges.subscribe((value) => {
            if (isNaN(value)) {
                this.form.controls['scale_min_to'].setErrors({ required: true });
                this.form.controls['scale_min_to'].markAsTouched();
                return;
            }
            if (+value >= +this.form.controls['scale_max_to'].value) {
                this.form.controls['scale_min_to'].setErrors({ tooBig: true });
                this.form.controls['scale_min_to'].markAsTouched();
            } else {
                this.form.controls['scale_min_from'].setErrors({ tooBig: false });
                this.form.controls['scale_min_from'].markAsTouched();
                this.form.controls['scale_min_from'].updateValueAndValidity();
            }
            if (+this.form.get('scale_max_to').value > +this.form.controls['scale_min_to'].value) {
                this.form.controls['scale_max_to'].setErrors(null);
                this.form.controls['scale_max_to'].markAsTouched();
            }
            this.validateAlarmSettings();
        });
    }

    // -----------------------------------------------------------------
    // listens to ScaleMaxTo value changes
    // -----------------------------------------------------------------
    listenToScaleMaxTo(): void {
        if (!this.form.get('scale_max_to')) {
            return;
        }
        this.form.get('scale_max_to').valueChanges.subscribe((value) => {
            if (isNaN(value)) {
                this.form.controls['scale_max_to'].setErrors({ required: true });
                this.form.controls['scale_max_to'].markAsTouched();
                return;
            }
            if (+value <= +this.form.controls['scale_min_to'].value) {
                this.form.controls['scale_max_to'].setErrors({ tooBig: true });
                this.form.controls['scale_max_to'].markAsTouched();
            } else {
                this.form.controls['scale_max_from'].setErrors({ tooBig: false });
                this.form.controls['scale_max_from'].markAsTouched();
                this.form.controls['scale_max_from'].updateValueAndValidity();
            }
            if (+this.form.get('scale_min_to').value < +this.form.controls['scale_max_to'].value) {
                this.form.controls['scale_min_to'].setErrors(null);
                this.form.controls['scale_min_to'].markAsTouched();
            }
            this.validateAlarmSettings();
        });
    }

    // -----------------------------------------------------------------
    // listens to alarm persistence value changes
    // -----------------------------------------------------------------
    listenToAnalogAlarmPersistence(): void {
        // Determine whether input type is ac or dc
        const control = this.electricalCurrent === 'ac' ? 'alarm_persistence_ac_70' : 'alarm_persistence_dc_50';

        // Subscribe to input changes
        this.form.get(control).valueChanges.subscribe(() => {
            const channelSetting = this._channelSettingsService.getChValue(control, this.getInputType());
            if (+this.form.controls[control].value > channelSetting.max) {
                this.form.controls[control].setErrors({ minMaxExceeded: true });
                this.form.controls[control].markAsTouched();
                return;
            }
            if (+this.form.controls[control].value < channelSetting.min) {
                this.form.controls[control].setErrors({ minMaxExceeded: true });
                this.form.controls[control].markAsTouched();
                return;
            }
            this.form.controls[control].setErrors(null);
            this.form.controls[control].markAsTouched();
        });
    }

    // -----------------------------------------------------------------
    // listens to alarm persistence value changes
    // -----------------------------------------------------------------
    listenToDigitalAlarmPersistence(): void {
        const control = 'alarm_persistence_50';

        // Subscribe to input changes
        this.form.get(control).valueChanges.subscribe((value) => {
            const channelSetting = this._channelSettingsService.getChValue(control, null);
            if (+this.form.controls[control].value > channelSetting.max) {
                this.form.controls[control].setErrors({ minMaxExceeded: true });
                this.form.controls[control].markAsTouched();
                return;
            }
            if (+this.form.controls[control].value < channelSetting.min) {
                this.form.controls[control].setErrors({ minMaxExceeded: true });
                this.form.controls[control].markAsTouched();
                return;
            }
            this.form.controls[control].setErrors(null);
            this.form.controls[control].markAsTouched();
        });
    }

    showBatteryAlert(): void {
        this.batteryAlarmTooltip.disabled = false;
        this.batteryAlarmTooltip.show();

        setTimeout(() => {
            this.batteryAlarmTooltip.disabled = true;
            this.batteryAlarmTooltip.hide();
        }, 10000);
    }

    // -----------------------------------------------------------------
    // listens to alarm toggle value changes
    // -----------------------------------------------------------------
    listenToAnalogAlarmToggle(): void {
        const input = this.getInputType();

        const lowAlarmToggleControl = this.electricalCurrent === 'ac' ? 'lo_alarm_enable_ac_79' : 'lo_alarm_enable_dc_59';
        const highAlarmToggleControl = this.electricalCurrent === 'ac' ? 'hi_alarm_enable_ac_73' : 'hi_alarm_enable_dc_53';

        const lowAlarmThresholdControl = this.electricalCurrent === 'ac' ? 'lo_alarm_threshold_ac_77' : 'lo_alarm_threshold_dc_57';
        const highAlarmThresholdControl = this.electricalCurrent === 'ac' ? 'hi_alarm_threshold_ac_71' : 'hi_alarm_threshold_dc_51';

        const lowAlarmDeadbandControl = this.electricalCurrent === 'ac' ? 'lo_alarm_deadband_ac_78' : 'lo_alarm_deadband_dc_58';
        const highAlarmDeadbandControl = this.electricalCurrent === 'ac' ? 'hi_alarm_deadband_ac_72' : 'hi_alarm_deadband_dc_52';

        // Get the min and max if shunt is enabled
        const min = this.form?.controls?.['shunt_enabled']?.value === 1 ? this.form?.controls?.['scale_min_to']?.value : null;
        const max = this.form?.controls?.['shunt_enabled']?.value === 1 ? this.form?.controls?.['scale_max_to']?.value : null;

        let analogChannelConfiguration = RM5AnalogChannels.get(this.unit.brandId)[this.channelIndex - 1];

        // When low alarm is toggled
        this.form.get(lowAlarmToggleControl).valueChanges.subscribe((isEnabled) => {
            // If the low alarm is enabled, add hi/lo alarm threshold range constraints
            if (isEnabled) {
                // Add the low alarm threshold control and then validate it
                this.form.addControl(lowAlarmThresholdControl, new FormControl(this.reportedAnalogReading?.[lowAlarmThresholdControl]
                    ?? this._channelSettingsService.getChValue(lowAlarmThresholdControl, input).default, [Validators.min(this._channelSettingsService.getChValue(lowAlarmThresholdControl, input, min, max).min),
                    Validators.max(this._channelSettingsService.getChValue(lowAlarmThresholdControl, input, min, max).max), Validators.required]));

                this.form.addControl(lowAlarmDeadbandControl, new FormControl(this.reportedAnalogReading?.[lowAlarmDeadbandControl]
                    ?? this._channelSettingsService.getChValue(lowAlarmDeadbandControl, input).default));

                if (analogChannelConfiguration.batterySaverAlert) {
                    this.showBatteryAlert();
                }
            }

            // If the low alarm is disabled, remove high alarm threshold range constraints
            if (!isEnabled) {
                this.form.removeControl(lowAlarmThresholdControl);
                this.form.removeControl(lowAlarmDeadbandControl);
            }

            // Check settings and validate
            setTimeout(() => {
                this.validateScalingSettings();
                this.listenToAlarmSettings();
            }, 100);
        });

        // When high alarm is toggled
        this.form.get(highAlarmToggleControl).valueChanges.subscribe((isEnabled) => {
            // If the high alarm is enabled, add hi/lo alarm threshold range constraints
            if (isEnabled) {
                // Add the high alarm threshold control and then validate it
                this.form.addControl(highAlarmThresholdControl, new FormControl(this.reportedAnalogReading?.[highAlarmThresholdControl]
                    ?? this._channelSettingsService.getChValue(highAlarmThresholdControl, input).default, [Validators.min(this._channelSettingsService.getChValue(highAlarmThresholdControl, input, min, max).min),
                    Validators.max(this._channelSettingsService.getChValue(highAlarmThresholdControl, input, min, max).max), Validators.required]));

                this.form.addControl(highAlarmDeadbandControl, new FormControl(this.reportedAnalogReading?.[highAlarmDeadbandControl]
                    ?? this._channelSettingsService.getChValue(highAlarmDeadbandControl, input).default));

                if (analogChannelConfiguration.batterySaverAlert) {
                    this.showBatteryAlert();
                }
            }

            // If the high alarm is disabled, remove high alarm threshold range constraints
            if (!isEnabled) {
                this.form.removeControl(highAlarmThresholdControl);
                this.form.removeControl(highAlarmDeadbandControl);
            }

            // Check settings and validate
            setTimeout(() => {
                this.validateScalingSettings();
                this.listenToAlarmSettings();
            }, 100);
        });
    }

    // -----------------------------------------------------------------
    // Validates all the scaling settings
    // -----------------------------------------------------------------
    validateScalingSettings(): void {
        const bool = this._formService.convertControlToBoolean(this.form, 'shunt_enabled');
        if (bool && (!this.analogChannelConfiguration.multipleScalling || this.form.controls[`${this.electricalCurrent}_input_type`].value === InputTypeEnum.volts)) {
            this.form.get('scale_min_from').updateValueAndValidity({ onlySelf: false, emitEvent: true });
            this.form.get('scale_min_to').updateValueAndValidity({ onlySelf: false, emitEvent: true });
            this.form.get('scale_max_from').updateValueAndValidity({ onlySelf: false, emitEvent: true });
            this.form.get('scale_max_to').updateValueAndValidity({ onlySelf: false, emitEvent: true });
        }
        if (bool && (this.analogChannelConfiguration.multipleScalling && this.form.controls[`${this.electricalCurrent}_input_type`].value === InputTypeEnum.milliVolts)) {
            this.form.get('shunt_volt_input').updateValueAndValidity({ onlySelf: false, emitEvent: true });
            this.form.get('shunt_amps_scaled').updateValueAndValidity({ onlySelf: false, emitEvent: true });
        }
    }

    // -----------------------------------------------------------------
    // listens to Alarm deadband value changes
    // -----------------------------------------------------------------
    listenToAlarmSettings(): void {
        const requiredMessage = 'A value is required';

        // high alarm values
        const hiAlarmEnabled = this.form.controls[this.electricalCurrent === 'ac' ? 'hi_alarm_enable_ac_73' : 'hi_alarm_enable_dc_53']?.value;

        const hiAlarmThresholdControlName = this.electricalCurrent === 'ac' ? 'hi_alarm_threshold_ac_71' : 'hi_alarm_threshold_dc_51';
        const hiAlarmThresholdControl = this.form.controls[hiAlarmThresholdControlName];

        const hiAlarmDeadbandControlName = this.electricalCurrent === 'ac' ? 'hi_alarm_deadband_ac_72' : 'hi_alarm_deadband_dc_52';
        const hiAlarmDeadbandControl = this.form.controls[hiAlarmDeadbandControlName];

        if (hiAlarmEnabled) {
            // When High Alarm threshold is updated
            hiAlarmThresholdControl.valueChanges.subscribe((value) => {
                if (isNaN(value)) {
                    this.form.controls[hiAlarmThresholdControlName].setErrors({ valueRequired: requiredMessage });
                    this.form.controls[hiAlarmThresholdControlName].markAsTouched();
                    return;
                }
                this.validateAlarmSettings();
            });

            // When High Alarm deadband is updated
            hiAlarmDeadbandControl.valueChanges.subscribe((value) => {
                if (isNaN(value)) {
                    this.form.controls[hiAlarmDeadbandControlName].setErrors({ valueRequired: requiredMessage });
                    this.form.controls[hiAlarmDeadbandControlName].markAsTouched();
                    return;
                }
                this.validateAlarmSettings();
            });
        }

        // low alarm values
        const loAlarmEnabled = this.form.controls[this.electricalCurrent === 'ac' ? 'lo_alarm_enable_ac_79' : 'lo_alarm_enable_dc_59']?.value;

        const loAlarmThresholdControlName = this.electricalCurrent === 'ac' ? 'lo_alarm_threshold_ac_77' : 'lo_alarm_threshold_dc_57';
        const loAlarmThresholdControl = this.form.controls[loAlarmThresholdControlName];

        const loAlarmDeadbandControlName = this.electricalCurrent === 'ac' ? 'lo_alarm_deadband_ac_78' : 'lo_alarm_deadband_dc_58';
        const loAlarmDeadbandControl = this.form.controls[loAlarmDeadbandControlName];

        // Handle low alarm deadband
        if (loAlarmEnabled) {
            // When High Alarm threshold is updated
            loAlarmThresholdControl.valueChanges.subscribe((value) => {
                if (isNaN(value)) {
                    this.form.controls[loAlarmThresholdControlName].setErrors({ valueRequired: requiredMessage });
                    this.form.controls[loAlarmThresholdControlName].markAsTouched();
                    return;
                }
                this.validateAlarmSettings();
            });

            // When High Alarm deadband is updated
            loAlarmDeadbandControl.valueChanges.subscribe((value) => {
                if (isNaN(value)) {
                    this.form.controls[loAlarmDeadbandControlName].setErrors({ valueRequired: requiredMessage });
                    this.form.controls[loAlarmDeadbandControlName].markAsTouched();
                    return;
                }
                this.validateAlarmSettings();
            });
        }
        this.validateAlarmSettings();
    }

    // -----------------------------------------------------------------
    // Validates all the alarm settings
    // -----------------------------------------------------------------
    validateAlarmSettings(): void {
        // If the channel is not analog, not need to validate the alarm settings
        if (!this.isAnalog) {
            return;
        }

        // high alarm values
        const hiAlarmEnabled = this.form.controls[this.electricalCurrent === 'ac' ? 'hi_alarm_enable_ac_73' : 'hi_alarm_enable_dc_53']?.value;
        // Get the hi alarm threshold
        const hiAlarmThresholdControlName = this.electricalCurrent === 'ac' ? 'hi_alarm_threshold_ac_71' : 'hi_alarm_threshold_dc_51';
        const hiAlarmThresholdControl = this.form.controls[hiAlarmThresholdControlName];
        // Get the hi alarm deadband
        const hiAlarmDeadbandControlName = this.electricalCurrent === 'ac' ? 'hi_alarm_deadband_ac_72' : 'hi_alarm_deadband_dc_52';
        const hiAlarmDeadbandControl = this.form.controls[hiAlarmDeadbandControlName];
        // low alarm values
        const loAlarmEnabled = this.form.controls[this.electricalCurrent === 'ac' ? 'lo_alarm_enable_ac_79' : 'lo_alarm_enable_dc_59']?.value;
        // Get the low alarm threshold
        const loAlarmThresholdControlName = this.electricalCurrent === 'ac' ? 'lo_alarm_threshold_ac_77' : 'lo_alarm_threshold_dc_57';
        const loAlarmThresholdControl = this.form.controls[loAlarmThresholdControlName];
        // Get the lo alarm deadband
        const loAlarmDeadbandControlName = this.electricalCurrent === 'ac' ? 'lo_alarm_deadband_ac_78' : 'lo_alarm_deadband_dc_58';
        const loAlarmDeadbandControl = this.form.controls[loAlarmDeadbandControlName];

        // Get the min max allowed based on the electric potential
        const threshold = this.getThresholdByMeasurement();
        const channelSettingsMinMax = this._channelSettingsService.getChValue(hiAlarmThresholdControlName, this.getInputType(), threshold.min, threshold.max);

        // Initialize errors
        const hiAlarmThresholdErrors = {};
        const hiAlarmDeadbandErrors = {};
        const loAlarmThresholdErrors = {};
        const loAlarmDeadbandErrors = {};

        // Check there are threshold values
        const requiredMessage = 'A value is required';
        if (hiAlarmEnabled && (hiAlarmThresholdControl?.value === '' || isNaN(hiAlarmThresholdControl?.value))) {
            hiAlarmThresholdErrors['valueRequired'] = requiredMessage;
        }
        if (loAlarmEnabled && (loAlarmThresholdControl?.value === '' || isNaN(loAlarmThresholdControl?.value))) {
            loAlarmThresholdErrors['valueRequired'] = requiredMessage;
        }

        // Check there are deadband values
        if (hiAlarmEnabled && (hiAlarmDeadbandControl?.value === '' || isNaN(hiAlarmDeadbandControl?.value))) {
            hiAlarmDeadbandErrors['valueRequired'] = requiredMessage;
        }
        if (loAlarmEnabled && (loAlarmDeadbandControl?.value === '' || isNaN(loAlarmDeadbandControl?.value))) {
            loAlarmDeadbandErrors['valueRequired'] = requiredMessage;
        }

        // Get the high alarm values
        const highAlarmHigh = Math.round((hiAlarmThresholdControl?.value + (hiAlarmDeadbandControl?.value ?? 0)) * 100) / 100;
        const highAlarmCenter = Math.round((hiAlarmThresholdControl?.value) * 100) / 100;
        const highAlarmLow = Math.round((hiAlarmThresholdControl?.value - (hiAlarmDeadbandControl?.value ?? 0)) * 100) / 100;
        const highAlarmDeadband = Math.round((hiAlarmDeadbandControl?.value ?? 0) * 100) / 100;
        // Get the low alarm values
        const lowAlarmHigh = Math.round((loAlarmThresholdControl?.value + (loAlarmDeadbandControl?.value ?? 0)) * 100) / 100;
        const lowAlarmCenter = Math.round((loAlarmThresholdControl?.value) * 100) / 100;
        const lowAlarmLow = Math.round((loAlarmThresholdControl?.value - (loAlarmDeadbandControl?.value ?? 0)) * 100) / 100;
        const lowAlarmDeadband = Math.round((loAlarmDeadbandControl?.value ?? 0) * 100) / 100;
        // Get the electrical potential
        const electricalPotential = `${this.form.controls[this.electricalCurrent + '_input_type'].value === 1 ? 'volts' : 'millivolts'}`;

        // Check that high and low alarm don't exceed the min and max values allowed
        if (hiAlarmEnabled) {
            if ((highAlarmCenter > channelSettingsMinMax.max) || (highAlarmCenter < channelSettingsMinMax?.min)) {
                hiAlarmThresholdErrors['minMaxExceeded'] = `Value must be between ${this.getMinMaxThreshold('hi').min} and ${this.getMinMaxThreshold('hi').max} ${electricalPotential}`;
            }
            if ((highAlarmDeadband > channelSettingsMinMax.max) || (highAlarmDeadband < 0)) {
                hiAlarmDeadbandErrors['minMaxExceeded'] = `Value must be between 0 and ${this.getMinMaxThreshold('hi').max} ${electricalPotential}`;
            }
        }
        if (loAlarmEnabled) {
            if ((lowAlarmCenter > channelSettingsMinMax.max) || (lowAlarmCenter < channelSettingsMinMax?.min)) {
                loAlarmThresholdErrors['minMaxExceeded'] = `Value must be between ${this.getMinMaxThreshold('hi').min} and ${this.getMinMaxThreshold('hi').max} ${electricalPotential}`;
            }
            if ((lowAlarmDeadband > channelSettingsMinMax.max) || (lowAlarmDeadband < 0)) {
                loAlarmDeadbandErrors['minMaxExceeded'] = `Value must be between 0 and ${this.getMinMaxThreshold('hi').max} ${electricalPotential}`;
            }
        }

        // Check high and low alarm thresholds
        if (hiAlarmEnabled) {
            if (highAlarmHigh > channelSettingsMinMax.max || highAlarmLow < channelSettingsMinMax.min) {
                const error = `The aggregate total of high alarm threshold and high alarm deadband must be between ${this.getMinMaxThreshold('hi').min} and ${this.getMinMaxThreshold('hi').max} ${electricalPotential}.
                The current deadband range is between ${highAlarmLow} and ${highAlarmHigh} ${electricalPotential}`;
                hiAlarmThresholdErrors['lessThanGreaterThanError'] = error;
                hiAlarmDeadbandErrors['lessThanGreaterThanError'] = error;
            }
        }

        // Check high and low alarm thresholds
        if (loAlarmEnabled) {
            if (lowAlarmHigh > channelSettingsMinMax.max || lowAlarmHigh < channelSettingsMinMax.min) {
                const error = `The aggregate total of low alarm threshold and low alarm deadband must be between ${this.getMinMaxThreshold('hi').min} and ${this.getMinMaxThreshold('hi').max} ${electricalPotential}.
                The current deadband range is between ${lowAlarmLow} and ${lowAlarmHigh} ${electricalPotential}`;
                loAlarmThresholdErrors['lessThanGreaterThanError'] = error;
                loAlarmDeadbandErrors['lessThanGreaterThanError'] = error;
            }
        }

        // Check that the aggregate total of low alarm is less than high alarm
        if (hiAlarmEnabled && loAlarmEnabled) {
            if (lowAlarmHigh >= highAlarmLow) {
                const error = `The aggregate total of low alarm threshold and low alarm deadband must be less than ${highAlarmLow} ${electricalPotential}`;
                loAlarmThresholdErrors['lessThanGreaterThanError'] = error;
                loAlarmDeadbandErrors['lessThanGreaterThanError'] = error;
            }
        }

        // Update high alarm threshold form controls
        if (hiAlarmThresholdControl) {
            if (Object.keys(hiAlarmThresholdErrors).length) {
                hiAlarmThresholdControl.setErrors(hiAlarmThresholdErrors);
                hiAlarmThresholdControl.markAsTouched();
            } else {
                hiAlarmThresholdControl.setErrors(null);
                hiAlarmThresholdControl.markAsTouched();
            }
        }

        // Update high alarm deadband form controls
        if (hiAlarmDeadbandControl) {
            if (Object.keys(hiAlarmDeadbandErrors).length) {
                hiAlarmDeadbandControl.setErrors(hiAlarmDeadbandErrors);
                hiAlarmDeadbandControl.markAsTouched();
            } else {
                hiAlarmDeadbandControl.setErrors(null);
                hiAlarmDeadbandControl.markAsTouched();
            }
        }

        // Update low alarm threshold form controls
        if (loAlarmThresholdControl) {
            if (Object.keys(loAlarmThresholdErrors).length) {
                loAlarmThresholdControl.setErrors(loAlarmThresholdErrors);
                loAlarmThresholdControl.markAsTouched();
            } else {
                loAlarmThresholdControl.setErrors(null);
                loAlarmThresholdControl.markAsTouched();
            }
        }

        // Update low alarm deadband form controls
        if (loAlarmDeadbandControl) {
            if (Object.keys(loAlarmDeadbandErrors).length) {
                loAlarmDeadbandControl.setErrors(loAlarmDeadbandErrors);
                loAlarmDeadbandControl.markAsTouched();
            } else {
                loAlarmDeadbandControl.setErrors(null);
                loAlarmDeadbandControl.markAsTouched();
            }
        }
    }

    // -----------------------------------------------------------------
    // Gets the min max by measurement type
    // -----------------------------------------------------------------
    getThresholdByMeasurement(): any {
        // Set the new min value for high and low alarm threshold
        let min = null;
        let max = null;
        const bool = this._formService.convertControlToBoolean(this.form, 'shunt_enabled');
        if (bool && this.electricalCurrent === 'dc') {
            min = (!this.analogChannelConfiguration.multipleScalling || this.form?.controls?.['dc_input_type']?.value === 1) ? this.form?.controls?.['scale_min_to']?.value : 0;
            max = (!this.analogChannelConfiguration.multipleScalling || this.form?.controls?.['dc_input_type']?.value === 1) ? this.form?.controls?.['scale_max_to']?.value : this.form?.controls?.['shunt_amps_scaled']?.value;
        }
        return { min, max };
    }

    // -----------------------------------------------------------------
    // Gets the min max input type
    // -----------------------------------------------------------------
    getMinMaxThreshold(value: string): ChannelSetting {
        // Get the measurement type and set the control
        const loAlarmControl = this.electricalCurrent === 'ac' ? 'lo_alarm_threshold_ac_77' : 'lo_alarm_threshold_dc_57';
        const hiAlarmControl = this.electricalCurrent === 'ac' ? 'hi_alarm_threshold_ac_71' : 'hi_alarm_threshold_dc_51';

        // Set the new min value for high and low alarm threshold
        const threshold = this.getThresholdByMeasurement();
        return this._channelSettingsService.getChValue(value === 'hi' ? hiAlarmControl : loAlarmControl, this.getInputType(), threshold.min, threshold.max);
    }

    // -----------------------------------------------------------------
    // Gets the input type
    // -----------------------------------------------------------------
    getInputType(): number {
        return this.form.controls[this.isAnalog ? `${this.electricalCurrent}_input_type` : `${this.electricalCurrent}`].value;
    }

    // -----------------------------------------------------------------
    // Gets the min max input type
    // -----------------------------------------------------------------
    getMinMaxPersistence(): ChannelSetting {
        const control = this.electricalCurrent === 'ac' ? 'alarm_persistence_ac_70' : 'alarm_persistence_dc_50';
        return this._channelSettingsService.getChValue(control, this.getInputType());
    }

    // -----------------------------------------------------------------------------------------------------
    // handle engineering units input changed event
    // -----------------------------------------------------------------------------------------------------
    listenToEngineeringUnitsLabel(): void {
        this.form.get('display_unit_label').valueChanges.subscribe((value) => {
            if (!value || value.length > 20) {
                this.form.get('display_unit_label').setErrors({ valueRequired: 'requiredMessage' });
            } else {
                this.form.get('display_unit_label').setErrors(null);
                this.form.get('display_unit_label').markAsTouched();
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // Update the autocompleteInput length
    // -----------------------------------------------------------------------------------------------------
    onChangeSearch(e: any): void {
        if (e.length > 20) {
            this.form.controls.display_unit_label.setValue(this.form.controls.display_unit_label.value.substring(0, 20));
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // ngOnDestroy
    // -----------------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
