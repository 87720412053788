import { Injectable } from '@angular/core';
import { PendingReportingFormModel, ReportingFormModel, ReportingFormResponse, ScheduleOption } from './reporting.models';
import { FormGroup } from '@angular/forms';
import { ScheduleModeEnum } from './reporting.enums';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Unit } from '@ai/ngx-concentric/lib/models/unit';
import { DatePipe } from '@angular/common';
import { ApiService, Dictionary } from '@ai/ngx-concentric';
import { environment } from '../../../../environments/environment';
import { UnitService } from '../../../core/services/unit.service';
import { DataPointEnum } from '../../../core/enums/data-point.enum';
import { UnitDataPoint } from '../../../core/models/unit-data-point.model';
import { ChannelReadingsViewModel } from '../readings-and-settings/readings/readings.model';
import { AlertService } from 'app/core/services/alert.service';
import { UnitStyleEnum } from 'app/core/enums/unit-style.enum';
import { BrandType } from 'app/core/enums/brand-type.enum';

@Injectable({ providedIn: 'root' })
export class ReportingService {
    public scheduleMode = ScheduleModeEnum;
    private _dataPointEnum = DataPointEnum;
    private _retryCount: number = 3;

    constructor(private _apiService: ApiService,
        private _datePipe: DatePipe,
        private _unitService: UnitService,
        private _alertService: AlertService) {
    }

    // -----------------------------------------------------------------
    // Returns array of ReportingModeModel
    // -----------------------------------------------------------------
    public getScheduleModes(brandId: number): Dictionary<string, number>[] {
        const scheduleOptionsAll: ScheduleOption[] = [
            { key: 'Off', value: 0 },
            { key: 'Repeat', value: 1 },
            { key: 'Day Of Month', value: 2 }
        ];

        const scheduleOptionsRepeatAndDayOfMonth: ScheduleOption[] = [
            { key: 'Repeat', value: 1 },
            { key: 'Day Of Month', value: 2 }
        ];

        const brandScheduleOptions: Record<number, ScheduleOption[]> = {
            65: scheduleOptionsAll,
            66: scheduleOptionsAll,
            67: scheduleOptionsAll,
            68: scheduleOptionsRepeatAndDayOfMonth,
            69: scheduleOptionsRepeatAndDayOfMonth,
            70: scheduleOptionsRepeatAndDayOfMonth,
            71: scheduleOptionsRepeatAndDayOfMonth,
            72: scheduleOptionsRepeatAndDayOfMonth,
            73: scheduleOptionsRepeatAndDayOfMonth
        };

        return brandScheduleOptions[brandId];
    }

    // -----------------------------------------------------------------
    // Returns array of ReportingIntervalModel
    //  -----------------------------------------------------------------
    public async getReportingIntervalsAsync(): Promise<Dictionary<string, number>[]> {
        let reportingIntervals = [];
        const unitId = this._unitService.getUnitId();
        if (unitId) {
            const getReportingIntervalResponse = await this._apiService.getAsync(`${environment.bullhornApiUrl}/Bill/GetReportingIntervalAsync?unitId=${unitId}`).then().catch(() => {
                return [];
            });
            reportingIntervals = getReportingIntervalResponse['result'] ?? [];
        }
        return reportingIntervals;
    }

    // -----------------------------------------------------------------
    // Returns array of DayOfMonth
    //  -----------------------------------------------------------------
    public getDaysOfMonth(): Dictionary<string, number>[] {
        const daysOfTheMonth: Dictionary<string, number>[] = [];
        for (let i = 1; i < 29; i++) {
            daysOfTheMonth.push({ key: `${i}${this.returnDaySuffix(i)}`, value: i });
        }
        return daysOfTheMonth;
    }

    // -----------------------------------------------------------------
    // Returns the day suffix
    //  -----------------------------------------------------------------
    returnDaySuffix(d: number): string {
        if (d > 3 && d < 21) {
            return 'th';
        }
        switch (d % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }

    // -----------------------------------------------------------------
    // Helps build the form control values
    //  -----------------------------------------------------------------
    public async getFormControlsAsync(unit: Unit): Promise<ReportingFormResponse> {
        debugger;
        let reportedProperties = null;
        let objectId = 'ai_reporting_31016';
        
        if (unit && unit.unitSettings) {
            reportedProperties = unit.unitSettings?.['properties']?.['reported']?.[objectId]?.[0];            
        }

        if(reportedProperties){
            return await this.buildFormControlFor31016(unit);
        }
        else{
            return await this.buildFormControlFor31015(unit);
        }

    }

    async buildFormControlFor31016(unit: Unit):Promise<any> {
        let objectId = 'ai_reporting_31016';
        let desiredProperties = unit.unitSettings?.['properties']?.['desired']?.[objectId]?.[0];
        let reportedProperties = unit.unitSettings?.['properties']?.['reported']?.[objectId]?.[0];
        const model: Partial<ReportingFormModel> = {};
        const pendingModel: Partial<PendingReportingFormModel> = {};

        //------------------------------------------------------------------------------------------------
        // Schedule Mode
        //------------------------------------------------------------------------------------------------
        model.schedule_mode_1 = !reportedProperties ? 0 : +reportedProperties?.['schedule_mode_0'] ?? 0;
        if (desiredProperties?.['schedule_mode_0'] !== undefined) {
            pendingModel['schedule_mode_0'] = +desiredProperties?.['schedule_mode_0'] !== model['schedule_mode_0'];
        }

        //------------------------------------------------------------------------------------------------
        // Start Day
        //------------------------------------------------------------------------------------------------
        model.startDay = new Date();
        if (reportedProperties?.['start_time_1'] && reportedProperties?.['start_time_1']) {
            model.startDay = new Date(+reportedProperties['start_time_1'] * 1000);
            model.start_time_2 = this._datePipe.transform(new Date(model.startDay), 'hh:mm a');

            // Check pending value
            if (desiredProperties && desiredProperties?.['start_time_1']) {
                try {
                    const startDay = new Date(+desiredProperties['start_time_1'] * 1000);
                    const startTime = this._datePipe.transform(new Date(startDay), 'hh:mm a');
                    pendingModel.startDay = startDay.toDateString() !== model.startDay.toDateString();
                    pendingModel.start_time_2 = startTime !== model.start_time_2;
                } catch {
                }
            }
        }

        //------------------------------------------------------------------------------------------------
        // Repeat Interval
        //------------------------------------------------------------------------------------------------
        model.repeat_interval_3 = reportedProperties ? +reportedProperties['repeat_interval_2'] : null;
        if (!_.find(await this.getReportingIntervalsAsync(), { value: model.repeat_interval_3 })) {
            model.repeat_interval_3 = null;
        }
        if (desiredProperties && desiredProperties?.['repeat_interval_2']) {
            try {
                const desiredRepeatInterval = desiredProperties ? desiredProperties['repeat_interval_2'] : null;
                pendingModel['repeat_interval_2'] = +desiredRepeatInterval !== model.repeat_interval_3;
            } catch {
            }
        }

        //------------------------------------------------------------------------------------------------
        // Day Of Month
        //------------------------------------------------------------------------------------------------
        model.day_of_month_4 = reportedProperties ? reportedProperties['days_of_month_3'] : [];
        if (reportedProperties?.['days_of_month_3'] && reportedProperties?.['days_of_month_3']) {
            const resultArray: number[] = Object.values(reportedProperties['days_of_month_3']);
            const reportedDaysOfTheMonth: number[] = this.setReportedDaysOfTheMonthArray(resultArray, unit);
            model.day_of_month_4 = reportedDaysOfTheMonth;
        }

        if (desiredProperties && desiredProperties?.['days_of_month_3']) {
            try {
                const resultArray: number[] = Object.values(reportedProperties['days_of_month_3']);
                const desiredDaysOfTheMonth: number[] = this.setReportedDaysOfTheMonthArray(resultArray, unit);
                const sortedReported = model.day_of_month_4 ? model.day_of_month_4.sort((a, b) => a - b).toString() : null;
                const sortedDesired = desiredDaysOfTheMonth.sort((a, b) => a - b).toString();
                pendingModel['days_of_month_3'] = sortedDesired !== sortedReported;
            } catch {
            }
        }

        // Return values to populate the reactive form
        return {
            ReportingModel: model,
            ReportingPendingModel: pendingModel
        };
    }

    async buildFormControlFor31015(unit: Unit):Promise<any> {
        let objectId = 'ai_routine_31015';
        let desiredProperties = unit.unitSettings?.['properties']?.['desired']?.[objectId]?.[0];
        let reportedProperties = unit.unitSettings?.['properties']?.['reported']?.[objectId]?.[0];
        const model: Partial<ReportingFormModel> = {};
        const pendingModel: Partial<PendingReportingFormModel> = {};

        //------------------------------------------------------------------------------------------------
        // Schedule Mode
        //------------------------------------------------------------------------------------------------
        model.schedule_mode_1 = !reportedProperties ? 0 : +reportedProperties?.['schedule_mode_1'] ?? 0;
        if (desiredProperties?.['schedule_mode_1'] !== undefined) {
            pendingModel['schedule_mode_1'] = +desiredProperties?.['schedule_mode_1'] !== model['schedule_mode_1'];
        }

        //------------------------------------------------------------------------------------------------
        // Start Day
        //------------------------------------------------------------------------------------------------
        model.startDay = new Date();
        if (reportedProperties?.['start_time_2'] && reportedProperties?.['start_time_2']) {
            model.startDay = new Date(+reportedProperties['start_time_2'] * 1000);
            model.start_time_2 = this._datePipe.transform(new Date(model.startDay), 'hh:mm a');

            // Check pending value
            if (desiredProperties && desiredProperties?.['start_time_2']) {
                try {
                    const startDay = new Date(+desiredProperties['start_time_2'] * 1000);
                    const startTime = this._datePipe.transform(new Date(startDay), 'hh:mm a');
                    pendingModel.startDay = startDay.toDateString() !== model.startDay.toDateString();
                    pendingModel.start_time_2 = startTime !== model.start_time_2;
                } catch {
                }
            }
        }

        //------------------------------------------------------------------------------------------------
        // Repeat Interval
        //------------------------------------------------------------------------------------------------
        model.repeat_interval_3 = reportedProperties ? +reportedProperties['repeat_interval_3'] : null;
        if (!_.find(await this.getReportingIntervalsAsync(), { value: model.repeat_interval_3 })) {
            model.repeat_interval_3 = null;
        }
        if (desiredProperties && desiredProperties?.['repeat_interval_3']) {
            try {
                const desiredRepeatInterval = desiredProperties ? desiredProperties['repeat_interval_3'] : null;
                pendingModel['repeat_interval_3'] = +desiredRepeatInterval !== model.repeat_interval_3;
            } catch {
            }
        }

        //------------------------------------------------------------------------------------------------
        // Day Of Month
        //------------------------------------------------------------------------------------------------
        model.day_of_month_4 = reportedProperties ? reportedProperties['day_of_month_4'] : [];
        if (reportedProperties?.['day_of_month_4'] && reportedProperties?.['day_of_month_4']) {
            const resultArray: number[] = Object.values(reportedProperties['day_of_month_4']);
            const reportedDaysOfTheMonth: number[] = this.setReportedDaysOfTheMonthArray(resultArray, unit);
            model.day_of_month_4 = reportedDaysOfTheMonth;
        }

        if (desiredProperties && desiredProperties?.['day_of_month_4']) {
            try {
                const resultArray: number[] = Object.values(reportedProperties['day_of_month_4']);
                const desiredDaysOfTheMonth: number[] = this.setReportedDaysOfTheMonthArray(resultArray, unit);
                const sortedReported = model.day_of_month_4 ? model.day_of_month_4.sort((a, b) => a - b).toString() : null;
                const sortedDesired = desiredDaysOfTheMonth.sort((a, b) => a - b).toString();
                pendingModel['day_of_month_4'] = sortedDesired !== sortedReported;
            } catch {
            }
        }

        // Return values to populate the reactive form
        return {
            ReportingModel: model,
            ReportingPendingModel: pendingModel
        };
    }

    setReportedDaysOfTheMonthArray(dayOfMonth4Array: number[], unit: Unit): number[] {
        const reportedDaysOfTheMonth: number[] = [];
        if (unit?.brandId == BrandType.RM540C || unit?.brandId == BrandType.RM540S) {
            for (let i = 0; i < dayOfMonth4Array.length - 2; i++) {
                reportedDaysOfTheMonth.push(+dayOfMonth4Array[i]);
            }    
        }
        else {
            for (let i = 0; i < dayOfMonth4Array.length; i++) {
                reportedDaysOfTheMonth.push(+dayOfMonth4Array[i]);
            }
        }
        return reportedDaysOfTheMonth;
    }


    // -----------------------------------------------------------------
    // Builds the payload to update the device twin
    //  -----------------------------------------------------------------
    buildJsonPayload(originalForm: FormGroup, updatedForm: FormGroup, unit: Unit): Promise<any> {
        let desiredProperties = null;
        let reportedProperties = null;
        
        let objectId = 'ai_reporting_31016';
        
        if (unit && unit.unitSettings) {
            desiredProperties = unit.unitSettings?.['properties']?.['desired']?.[objectId]?.[0];
            reportedProperties = unit.unitSettings?.['properties']?.['reported']?.[objectId]?.[0];            
        }
        
        if(reportedProperties || desiredProperties){
            return this.buidlPayloadFor31016(originalForm, updatedForm);
        }
        else{
            return this.buidlPayloadFor31015(originalForm, updatedForm);
        }        
    }

    buidlPayloadFor31015(originalForm: FormGroup, updatedForm: FormGroup): any {
        // Initialize the JSON request (with calculated values)
        const request = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'ai_routine_31015': {
                '0': {}
            }
        };

        // Add schedule mode
        if (updatedForm.controls.scheduleMode) {
            request['ai_routine_31015']['0']['schedule_mode_1'] = updatedForm.controls.scheduleMode.value;
        }

        // Get start time in UTC seconds when routine should run.
        if (updatedForm.controls.startDay || updatedForm.controls.startTime) {
            const startDay = updatedForm.controls.startDay ?? originalForm.controls.startDay;
            const startTime = updatedForm.controls.startTime ?? originalForm.controls.startTime;
            const startDateString = moment(startDay.value).format('MM/DD/YYYY');
            const localToUtcDateTime = moment.utc(new Date(`${startDateString} ${startTime.value}`));
            request['ai_routine_31015']['0']['start_time_2'] = new Date(moment(localToUtcDateTime).toISOString()).getTime() / 1000;
        }

        // Get repeat interval in seconds of how often the routine should run. Mutually exclusive from Day of Month.
        if (updatedForm.controls.reportingInterval) {
            request['ai_routine_31015']['0']['repeat_interval_3'] = (updatedForm.controls.reportingInterval.value);
        }

        // Get "day of month" to run the steps of the routine. Mutually exclusive from Repeat Interval.
        if (updatedForm.controls.daysOfTheMonth) {
            const days = {};
            _.forEach(updatedForm.controls.daysOfTheMonth.value, (day, index) => {
                days[index] = day;
            });

            // Check length of array and null
            const length = updatedForm.controls.daysOfTheMonth.value.length;
            for (let i = length; i < 4; i++) {
                days[i] = null;
            }

            // Set the days
            if (days !== {}) {
                request['ai_routine_31015']['0']['day_of_month_4'] = days;
            }
        }

        return request;
    }

    buidlPayloadFor31016(originalForm: FormGroup, updatedForm: FormGroup): any {
        // Initialize the JSON request (with calculated values)
        const request = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'ai_reporting_31016': {
                '0': {}
            }
        };

        // Add schedule mode
        if (updatedForm.controls.scheduleMode) {
            request['ai_reporting_31016']['0']['schedule_mode_0'] = updatedForm.controls.scheduleMode.value;
        }

        // Get start time in UTC seconds when routine should run.
        if (updatedForm.controls.startDay || updatedForm.controls.startTime) {
            const startDay = updatedForm.controls.startDay ?? originalForm.controls.startDay;
            const startTime = updatedForm.controls.startTime ?? originalForm.controls.startTime;
            const startDateString = moment(startDay.value).format('MM/DD/YYYY');
            const localToUtcDateTime = moment.utc(new Date(`${startDateString} ${startTime.value}`));
            request['ai_reporting_31016']['0']['start_time_1'] = new Date(moment(localToUtcDateTime).toISOString()).getTime() / 1000;
        }

        // Get repeat interval in seconds of how often the routine should run. Mutually exclusive from Day of Month.
        if (updatedForm.controls.reportingInterval) {
            request['ai_reporting_31016']['0']['repeat_interval_2'] = (updatedForm.controls.reportingInterval.value);
        }

        // Get "day of month" to run the steps of the routine. Mutually exclusive from Repeat Interval.
        if (updatedForm.controls.daysOfTheMonth) {
            const days = {};
            _.forEach(updatedForm.controls.daysOfTheMonth.value, (day, index) => {
                days[index] = day;
            });

            // Check length of array and null
            const length = updatedForm.controls.daysOfTheMonth.value.length;
            for (let i = length; i < 4; i++) {
                days[i] = null;
            }

            // Set the days
            if (days !== {}) {
                request['ai_reporting_31016']['0']['days_of_month_3'] = days;
            }
        }
        console.log('request', request);
        return request;
    }

    // -----------------------------------------------------------------
    // Updates the routine steps to add or remove object keys based
    // on whether a channel is enabled or disabled
    // -----------------------------------------------------------------
    async updateRoutineSteps(unit: Unit, deviceId: string, instanceId: number): Promise<any> {
        let channelReadingsData = await this._unitService.getChannelReadingsAsync(unit.unitId, instanceId).then();
        let stepsData = this.buildSteps(unit, channelReadingsData);
        await this._apiService.putAsync(`${environment.bullhornApiUrl}/Ota/SendOtaRequestAsync`, {
            'deviceId': deviceId,
            'request': JSON.stringify({ 'ai_routine_31015': { 0: stepsData } })
        }, this._retryCount).catch(err => {
            this._alertService.error(err);
        });
    }

    // -----------------------------------------------------------------
    // Builds the steps using a static order
    // -----------------------------------------------------------------
    private buildSteps(unit: Unit, unitDataPoints: ChannelReadingsViewModel[]): any {
        if ((unit.brand.brandId == 72 || unit.brand.brandId == 73) && unit.brand.unitStyleId == UnitStyleEnum.SingleUnit) {
            return {
                'steps_5': {
                    '0': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog1DC })?.enabled ? 'r 31032/1/4' : '',
                    '1': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog1AC })?.enabled ? 'r 31032/1/5' : '',
                    '2': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog2DC })?.enabled ? 'r 31032/2/4' : '',
                    '3': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog2AC })?.enabled ? 'r 31032/2/5' : '',
                    '4': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog3DC })?.enabled ? 'r 31032/3/4' : '',
                    '5': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog3AC })?.enabled ? 'r 31032/3/5' : '',
                    '6': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog4DC })?.enabled ? 'r 31032/4/4' : '',
                    '7': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog4AC })?.enabled ? 'r 31032/4/5' : '',
                    '8': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.digitalInput5 })?.enabled ? 'r 31021/1/2' : '',
                    '9': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.digitalInput6 })?.enabled ? 'r 31021/2/2' : '',
                    '10': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.digitalAccumulator1 })?.enabled ? 'r 31021/1/10' : '',
                    '11': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.digitalAccumulator2 })?.enabled ? 'r 31021/2/10' : '',
                    '12': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.instantOff })?.enabled ? 'r 31040/0/27' : '',
                    '13': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.instantOff })?.enabled ? 'r 31040/0/29' : '',
                    '14': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.instantOff })?.enabled ? 'r 31040/0/28' : '',
                }
            };
        }
        else if (unit.brand.unitStyleId == UnitStyleEnum.Dome || unit.brand.unitStyleId == UnitStyleEnum.IOModule) {
            return {
                'steps_5': {
                    '0': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog1DC })?.enabled ? 'r 31032/1001/4' : '',
                    '1': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog1AC })?.enabled ? 'r 31032/1001/5' : '',
                    '2': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog2DC })?.enabled ? 'r 31032/1002/4' : '',
                    '3': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog2AC })?.enabled ? 'r 31032/1002/5' : '',
                    '4': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog3DC })?.enabled ? 'r 31032/1003/4' : '',
                    '5': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog3AC })?.enabled ? 'r 31032/1003/5' : '',
                    '6': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog4DC })?.enabled ? 'r 31032/1004/4' : '',
                    '7': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog4AC })?.enabled ? 'r 31032/1004/5' : '',
                    '8': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.digitalInput5 })?.enabled ? 'r 31021/1001/2' : '',
                    '9': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.digitalInput6 })?.enabled ? 'r 31021/1002/2' : '',
                    '10': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.digitalAccumulator1 })?.enabled ? 'r 31021/1001/10' : '',
                    '11': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.digitalAccumulator2 })?.enabled ? 'r 31021/1002/10' : '',
                    '12': '',
                    '13': '',
                    '14': '',
                }
            };
        }
        return {
            'steps_5': {
                '0': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog1DC })?.enabled ? 'r 31032/1/4' : '',
                '1': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog1AC })?.enabled ? 'r 31032/1/5' : '',
                '2': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog2DC })?.enabled ? 'r 31032/2/4' : '',
                '3': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog2AC })?.enabled ? 'r 31032/2/5' : '',
                '4': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog3DC })?.enabled ? 'r 31032/3/4' : '',
                '5': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog3AC })?.enabled ? 'r 31032/3/5' : '',
                '6': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog4DC })?.enabled ? 'r 31032/4/4' : '',
                '7': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.analog4AC })?.enabled ? 'r 31032/4/5' : '',
                '8': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.digitalInput5 })?.enabled ? 'r 31021/1/2' : '',
                '9': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.digitalInput6 })?.enabled ? 'r 31021/2/2' : '',
                '10': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.digitalAccumulator1 })?.enabled ? 'r 31021/1/10' : '',
                '11': _.find(unitDataPoints, { dataPointId: this._dataPointEnum.digitalAccumulator2 })?.enabled ? 'r 31021/2/10' : '',
                '12': '',
                '13': '',
                '14': '',
            }
        };
    };
}
