<core-drawer class="w-screen min-w-screen sm:w-140 sm:min-w-140 z-999" fixed
             [mode]="'over'"
             [name]="'settingsDrawer'"
             [position]="'right'"
             #settingsDrawer>

    <!------------------------------------------------------------>
    <!-- Drawer -->
    <!------------------------------------------------------------>
    <div *ngIf="form" class="flex flex-col w-full h-screen overflow-hidden bg-card bg-gray-100 pl-3" [formGroup]="form">
        <main class="flex-1 overflow-y-scroll">
            <!------------------------------------------------------------>
            <!-- Title -->
            <!------------------------------------------------------------>
            <div class="flex flex-col mt-3 bg-white shadow rounded-2xl ml-3 mr-3">
                <div class="flex flex-row items-center px-6 h-15 min-h-15 text-black">
                    <mat-icon class="icon-size-7 text-current" [svgIcon]="'mat_solid:tune'"></mat-icon>
                    <div class="flex flex-wrap">
                        <div class="ml-3 flex-col">
                            <div class="text-2xl font-bold">
                                Channel Settings
                            </div>
                        </div>
                    </div>
                </div>

                <!------------------------------------------------------------>
                <!-- Channel Name Input -->
                <!------------------------------------------------------------>
                <div class="pl-6 pr-6 flex flex-col">
                    <div class="input-container flex flex-col">
                        <mat-form-field class="flex-auto">
                            <mat-label>Channel Name</mat-label>
                            <input #dataPointName matInput formControlName="data_point_name" [maxlength]="50"
                                   id="data-point-channel-input-name">
                            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'mat_solid:memory'"></mat-icon>
                            <mat-hint align="start">Provide a name for
                                {{isAnalog ? (electricalCurrent | channelPipe) : 'digital'}}
                                channel {{isAnalog ? reportedAnalogReading.channel_1 : reportedDigitalReading.channel_1 - 4}}
                            </mat-hint>
                            <mat-error>Input name must be between 1 and 50 characters</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!------------------------------------------------------------>
                <!-- Enable Toggle -->
                <!------------------------------------------------------------>
                <div class="pt-3 pl-6 pr-6">
                    <div class="flex flex-col pb-3">
                        <div id="output-mode-container" class="flex flex-col">
                            <mat-slide-toggle *ngIf="isAnalog && electricalCurrent === 'ac'"
                                              id="enabled-toggle-{{electricalCurrent}}"
                                              formControlName="{{electricalCurrent}}_enabled" color="primary">
                                <div class="flex flex-row">
                                    {{form.controls[electricalCurrent + '_enabled'].value === false ? 'Hiding Channel' : 'Showing Channel'}}
                                </div>
                            </mat-slide-toggle>
                            <mat-slide-toggle *ngIf="isAnalog && electricalCurrent === 'dc'"
                                              id="enabled-toggle-{{electricalCurrent}}"
                                              formControlName="{{electricalCurrent}}_enabled" color="primary">
                                <div class="flex flex-row">
                                    {{form.controls[electricalCurrent + '_enabled'].value === false ? 'Hiding Channel' : 'Showing Channel'}}
                                </div>
                            </mat-slide-toggle>
                            <mat-slide-toggle *ngIf="!isAnalog" id="enabled-digital-toggle" formControlName="enabled"
                                              color="primary">
                                <div class="flex flex-row">
                                    {{form.controls.enabled.value === false ? 'Hiding Channel' : 'Showing Channel'}}
                                </div>
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>

            <!------------------------------------------------------------>
            <!-- Analog Form -->
            <!------------------------------------------------------------>
            <div *ngIf="isAnalog" class="flex flex-col pl-3 pr-3 mt-4">
                <!------------------------------------------------------------>
                <!-- Display Settings -->
                <!------------------------------------------------------------>
                <div *ngIf="form.controls[electricalCurrent === 'ac'? 'ac_enabled': 'dc_enabled'].value">
                    <div class="pb-4">
                        <div class="pt-4 pl-6 pr-6 pb-3 shadow rounded-2xl bg-white">
                            <div class="flex flex-row items-center">
                                <mat-icon class="icon-size-7 text-current" [svgIcon]="'mat_solid:monitor'"></mat-icon>
                                <div class="ml-3 text-2xl font-bold tracking-tight">Display Settings</div>
                            </div>
                            <div class="flex flex-col pt-2 pb-2">

                                <!------------------------------------------------------------>
                                <!-- Engineering Units Name -->
                                <!------------------------------------------------------------>
                                <div class="autocomplete-container2 flex flex-col">
                                    <div id="autocompleteInputContainer" class="ng-autocomplete">
                                        <mat-label class="font-semibold">Engineering Units</mat-label>
                                        <ng-autocomplete #autocompleteInput
                                                         Id="autocompleteInput"
                                                         (inputChanged)='onChangeSearch($event)'
                                                         [maxlength]="20"
                                                         [minlength]="1"
                                                         formControlName="display_unit_label"
                                                         [data]="pcsLabels"
                                                         placeholder="Enter an engineering unit label"
                                                         [itemTemplate]="itemTemplate">
                                        </ng-autocomplete>

                                        <ng-template #itemTemplate let-item>
                                            <a id="{{item}}" [innerHTML]="item"></a>
                                        </ng-template>
                                    </div>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Input Type -->
                                <!------------------------------------------------------------>
                                <div class="electrical-potential-container flex flex-col mt-4">
                                    <div class="flex flex-row">
                                        <mat-label class="font-semibold">Input Type</mat-label>
                                        <pending-indicator
                                            [show]="pendingAnalogReading.scratchpad_2[electricalCurrent + '_input_type']"></pending-indicator>
                                    </div>
                                    <mat-radio-group *ngIf="electricalCurrent === 'ac'" formControlName="ac_input_type"
                                                    aria-label="Select an option">
                                        <mat-radio-button color="primary" [value]=1>V</mat-radio-button>
                                        <mat-radio-button color="primary" [value]=2>mV</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-radio-group *ngIf="electricalCurrent === 'dc'" formControlName="dc_input_type"
                                                    aria-label="Select an option">
                                        <mat-radio-button color="primary" [disabled]="disabledVolts" [value]=1>V</mat-radio-button>
                                        <mat-radio-button color="primary" [value]=2>mV</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Decimal Places -->
                                <!------------------------------------------------------------>
                                <div *ngIf="electricalCurrent" class="decimal-places-container mt-4">
                                    <div class="flex flex-row">
                                        <mat-label class="font-semibold">Decimal Places</mat-label>
                                        <pending-indicator
                                            [show]="pendingAnalogReading.scratchpad_2[electricalCurrent + '_decimal_places']"></pending-indicator>
                                    </div>
                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="decrement" mat-icon-button matPrefix type="button"
                                                longPress
                                                (onLongPressing)="decrement(electricalCurrent + '_decimal_places', 0, 0)"
                                                (click)="decrement(electricalCurrent + '_decimal_places',0,0)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- decimal_places input -->
                                        <input id="{{ electricalCurrent + '_decimal-places-input'}}"
                                               class="text-center"
                                               [formControlName]="electricalCurrent + '_decimal_places'"
                                               type="textbox" Numeric decimals="0" inputmode="numeric" matInput
                                               [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="increment" mat-icon-button matSuffix type="button"
                                                longPress
                                                (onLongPressing)="increment(electricalCurrent + '_decimal_places', 10, 0)"
                                                (click)="increment(electricalCurrent + '_decimal_places', 10, 0)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>The channel's precision</mat-hint>
                                        <mat-error *ngIf="form.controls[electricalCurrent + '_decimal_places'].value === ''
                                || form.controls[electricalCurrent + '_decimal_places'].value < 0
                                || form.controls[electricalCurrent + '_decimal_places'].value > 10">
                                            You must provide a value between 0 and 10
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!------------------------------------------------------------>
                    <!-- Scaling Settings -->
                    <!------------------------------------------------------------>
                    <div
                        *ngIf="electricalCurrent && form.controls[electricalCurrent === 'ac'? 'ac_enabled': 'dc_enabled'].value && electricalCurrent === 'dc'"
                        class="pb-4">
                        <div class="pt-4 pl-6 pr-6 pb-3 shadow rounded-2xl bg-white">
                            <div class="flex flex-row items-center">
                                <mat-icon class="icon-size-7 text-current"
                                          [svgIcon]="'mat_solid:build_circle'"></mat-icon>
                                <div class="ml-3 text-2xl font-bold tracking-tight">Scaling Settings</div>
                            </div>
                            <div class="flex flex-col pb-2">
                                <!------------------------------------------------------------>
                                <!--Shunt Enabled Title-->
                                <!------------------------------------------------------------>
                                <div id="shunt-enabled-container" class="flex flex-col pt-2">

                                    <div class="flex flex-row">
                                        <mat-slide-toggle id="shunt-toggle"
                                                          formControlName="shunt_enabled"
                                                          color="primary">
                                            {{form.controls.shunt_enabled.value === false ? 'Scaling Disabled' : 'Scaling Enabled'}}
                                        </mat-slide-toggle>
                                        <pending-indicator
                                            [show]="pendingAnalogReading.scratchpad_2.shunt_enabled"></pending-indicator>
                                    </div>
                                </div>

                                <!------------------------------------------------------------>
                                <!--Shunt Form Controls-->
                                <!------------------------------------------------------------>
                                <div *ngIf="form.controls.shunt_enabled.value">
                                    <!------------------------------------------------------------>
                                    <!-- Shunt Volt Input - volts -->
                                    <!------------------------------------------------------------>
                                    <div *ngIf="analogChannelConfiguration.multipleScalling == false || (form.controls.shunt_enabled.value == 1 && form.controls[electricalCurrent + '_input_type'].value === 1)">
                                        <div
                                            class="grid grid-cols-4 gap-6 w-full mt-4 pt-4 pl-6 pr-6 pb-3 rounded-2xl bg-white border-solid border-2 border-light-blue-100">
                                            <!-- Scale Min From -->
                                            <div class="col-span-2">
                                                <div class="flex flex-row">
                                                    <mat-label class="font-semibold">Input Min</mat-label>
                                                    <pending-indicator
                                                        [show]="pendingAnalogReading.scratchpad_2.scale_min_from"></pending-indicator>
                                                </div>

                                                <mat-form-field class="w-full">
                                                    <!-- Decrement button -->
                                                    <button id="decrement-scale-min-from" mat-icon-button matPrefix
                                                            type="button"
                                                            longPress
                                                            (onLongPressing)="decrement('scale_min_from', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_from)"
                                                            (click)="decrement('scale_min_from', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_from)">
                                                        <mat-icon class="icon-size-5"
                                                                  [svgIcon]="'mat_solid:remove'"></mat-icon>
                                                    </button>
                                                    <!-- input -->
                                                    <input id="scale-min-from-input" class="text-center"
                                                           [formControlName]="'scale_min_from'"
                                                           matInput mask="separator.2" thousandSeparator=","
                                                           [allowNegativeNumbers]="true" 
                                                           [min]="unitMin" [max]="maxMin"
                                                           type="text" inputmode="decimal" [placeholder]="'0'">
                                                    <!-- Increment button -->
                                                    <button id="increment-scale-min-from" mat-icon-button matSuffix
                                                            type="button"
                                                            longPress
                                                            (onLongPressing)="increment('scale_min_from', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_from)"
                                                            (click)="increment('scale_min_from', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_from)">
                                                        <mat-icon class="icon-size-5"
                                                                  [svgIcon]="'mat_solid:add'"></mat-icon>
                                                    </button>
                                                    <mat-error *ngIf="form.controls.scale_min_from.errors?.tooBig">Input
                                                        Min
                                                        must be less than Input Max
                                                    </mat-error>
                                                    <mat-error *ngIf="form.controls.scale_min_from?.errors?.required || !form.controls.scale_min_from.value
                                                || form.controls.scale_min_from.value < analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_from
                                                || form.controls.scale_min_from.value > analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_from">
                                                        A value between
                                                        {{analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_from}} 
                                                        and {{analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_from}} is required
                                                    </mat-error>
                                                    <mat-hint>Measurement in {{form.controls[electricalCurrent + '_input_type'].value === 1 ? 'Volts' : 'Millivolts'}}</mat-hint>
                                                </mat-form-field>           
                                            </div>
                                            <!-- Scale Max From -->
                                            <div class="col-span-2">
                                                <div class="flex flex-row">
                                                    <mat-label class="font-semibold">Input Max</mat-label>
                                                    <pending-indicator
                                                        [show]="pendingAnalogReading.scratchpad_2.scale_max_from"></pending-indicator>
                                                </div>
                                                <mat-form-field class="w-full">
                                                    <!-- Decrement button -->
                                                    <button id="decrement-scale-max-from" mat-icon-button matPrefix
                                                            longPress type="button"
                                                            (onLongPressing)="decrement('scale_max_from', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_from)"
                                                            (click)="decrement('scale_max_from', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_from)">
                                                        <mat-icon class="icon-size-5"
                                                                  [svgIcon]="'mat_solid:remove'"></mat-icon>
                                                    </button>
                                                    <!-- scale_max_from input -->
                                                    <input id="scale-max-from-input" class="text-center"
                                                           [formControlName]="'scale_max_from'"
                                                           matInput mask="separator.2" thousandSeparator=","
                                                           [allowNegativeNumbers]="true" [min]="maxMin" [max]="unitMax"
                                                           type="text" inputmode="decimal" [placeholder]="'0'">
                                                    <!-- Increment button -->
                                                    <button id="increment-scale-max-from" mat-icon-button matSuffix
                                                            type="button"
                                                            longPress
                                                            (onLongPressing)="increment('scale_max_from', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_from)"
                                                            (click)="increment('scale_max_from', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_from)">
                                                        <mat-icon class="icon-size-5"
                                                                  [svgIcon]="'mat_solid:add'"></mat-icon>
                                                    </button>
                                                    <mat-error
                                                        *ngIf="form.controls.scale_max_from.errors?.tooBig === true">
                                                        Input Max must be greater than Input Min
                                                    </mat-error>
                                                    <mat-error *ngIf="form.controls.scale_max_from?.errors?.required || !form.controls.scale_max_from.value
                                                    || form.controls.scale_max_from.value > analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_from
                                                    || form.controls.scale_max_from.value < analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_from">
                                                        A value between
                                                        {{analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_from}} 
                                                        and {{analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_from}} is required
                                                    </mat-error>
                                                    <mat-hint>Measurement in {{form.controls[electricalCurrent + '_input_type'].value === 1 ? 'Volts' : 'Millivolts'}}</mat-hint>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div
                                            class="grid grid-cols-4 gap-6 w-full mt-4 pt-4 pl-6 pr-6 pb-3 rounded-2xl bg-white border-solid border-2 border-light-blue-100">
                                            <!-- Scale Min To -->
                                            <div class="col-span-2">
                                                <div class="flex flex-row">
                                                    <mat-label class="font-semibold">Scale Min</mat-label>
                                                    <pending-indicator
                                                        [show]="pendingAnalogReading.scratchpad_2.scale_min_to"></pending-indicator>
                                                </div>

                                                <mat-form-field class="w-full">
                                                    <!-- Decrement button -->
                                                    <button id="decrement-scale-min-to" mat-icon-button matPrefix
                                                            type="button"
                                                            longPress
                                                            (onLongPressing)="decrement('scale_min_to', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_to)"
                                                            (click)="decrement('scale_min_to', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_to)">
                                                        <mat-icon class="icon-size-5"
                                                                  [svgIcon]="'mat_solid:remove'"></mat-icon>
                                                    </button>
                                                    <!-- input -->
                                                    <input id="scale-min-to-input" class="text-center"
                                                           [formControlName]="'scale_min_to'"
                                                           matInput mask="separator.2" thousandSeparator=","
                                                           [allowNegativeNumbers]="true"
                                                           type="textbox" inputmode="decimal" [placeholder]="'0'">
                                                    <!-- Increment button -->
                                                    <button id="increment-scale-min-to" mat-icon-button matSuffix
                                                            type="button"
                                                            longPress
                                                            (onLongPressing)="increment('scale_min_to', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_to)"
                                                            (click)="increment('scale_min_to', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_to)">
                                                        <mat-icon class="icon-size-5"
                                                                  [svgIcon]="'mat_solid:add'"></mat-icon>
                                                    </button>
                                                    <mat-error *ngIf="form.controls.scale_min_to.errors?.tooBig">Scale
                                                        Min
                                                        must
                                                        be less than Scale Max
                                                    </mat-error>
                                                    <mat-error *ngIf="form.controls.scale_min_to?.errors?.required || !form.controls.scale_min_to.value
                                                    || form.controls.scale_min_to.value < analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_to
                                                    || form.controls.scale_min_to.value > analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_to">
                                                        A value between {{analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_to}} 
                                                        and {{analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_to}} is required
                                                    </mat-error>
                                                    <mat-hint>Scaled measurement</mat-hint>
                                                </mat-form-field>
                                            </div>
                                            <!-- Scale Max To -->
                                            <div class="col-span-2">
                                                <div class="flex flex-row">
                                                    <mat-label class="font-semibold">Scale Max</mat-label>
                                                    <pending-indicator
                                                        [show]="pendingAnalogReading.scratchpad_2.scale_max_to"></pending-indicator>
                                                </div>

                                                <mat-form-field class="w-full">
                                                    <!-- Decrement button -->
                                                    <button id="decrement-scale-max-to" mat-icon-button matPrefix
                                                            type="button"
                                                            longPress
                                                            (onLongPressing)="decrement('scale_max_to', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_to)"
                                                            (click)="decrement('scale_max_to', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_to)">
                                                        <mat-icon class="icon-size-5"
                                                                  [svgIcon]="'mat_solid:remove'"></mat-icon>
                                                    </button>
                                                    <!-- input -->
                                                    <input id="scale-max-to-input" class="text-center"
                                                           [formControlName]="'scale_max_to'"
                                                           matInput mask="separator.2" thousandSeparator=","
                                                           [allowNegativeNumbers]="true"
                                                           type="textbox" inputmode="decimal" [placeholder]="'0'">
                                                    <!-- Increment button -->
                                                    <button id="increment-scale-max-to" mat-icon-button matSuffix
                                                            type="button"
                                                            longPress
                                                            (onLongPressing)="increment('scale_max_to', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_to)"
                                                            (click)="increment('scale_max_to', analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_to)">
                                                        <mat-icon class="icon-size-5"
                                                                  [svgIcon]="'mat_solid:add'"></mat-icon>
                                                    </button>
                                                    <mat-error *ngIf="form.controls.scale_max_to.errors?.tooBig">Scale
                                                        Max
                                                        must
                                                        be greater than Scale Min
                                                    </mat-error>
                                                    <mat-error *ngIf="form.controls.scale_max_to?.errors?.required || !form.controls.scale_max_to.value
                                            || form.controls.scale_max_to.value < analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_to
                                            || form.controls.scale_max_to.value > analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_to">
                                                        A value between {{analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_min_to}}
                                                        and {{analogChannelConfiguration.channelScalling[+form.controls[electricalCurrent + '_input_type'].value-1].scale_max_to}} is required
                                                    </mat-error>
                                                    <mat-hint>Scaled measurement</mat-hint>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>

                                    <!------------------------------------------------------------>
                                    <!-- Shunt Volt Input - millivolts -->
                                    <!------------------------------------------------------------>
                                    <div *ngIf="analogChannelConfiguration.multipleScalling == true && form.controls[electricalCurrent + '_input_type'].value === 2">
                                        <div *ngIf="form.controls.shunt_enabled.value"
                                             class="shunt-input-container mt-4">
                                            <div class="flex flex-row">
                                                <mat-label
                                                    class="font-semibold">{{form.controls[electricalCurrent + '_input_type'].value === 1 ? 'Volts' : 'Millivolts'}}
                                                    Input
                                                </mat-label>
                                                <pending-indicator
                                                    [show]="pendingAnalogReading.scratchpad_2.shunt_volt_input"></pending-indicator>
                                            </div>
                                            <mat-form-field class="w-full">
                                                <!-- Decrement button -->
                                                <button id="shunt-volt-decrement" mat-icon-button matPrefix
                                                        type="button"
                                                        longPress (onLongPressing)="decrement('shunt_volt_input',0)"
                                                        (click)="decrement('shunt_volt_input',0)">
                                                    <mat-icon class="icon-size-5"
                                                              [svgIcon]="'mat_solid:remove'"></mat-icon>
                                                </button>
                                                <!-- input -->
                                                <input id="shunt-volt-input" class="text-center"
                                                       [formControlName]="'shunt_volt_input'"
                                                       matInput mask="separator.2" thousandSeparator=","
                                                       [allowNegativeNumbers]="true"
                                                       type="textbox" inputmode="decimal" [placeholder]="'0'">
                                                <!-- Increment button -->
                                                <button id="shunt-volt-increment" mat-icon-button matSuffix
                                                        type="button"
                                                        longPress
                                                        (onLongPressing)="increment('shunt_volt_input', 10000)"
                                                        (click)="increment('shunt_volt_input' ,10000)">
                                                    <mat-icon class="icon-size-5"
                                                              [svgIcon]="'mat_solid:add'"></mat-icon>
                                                </button>
                                                <mat-hint>The channel's shunt volt input
                                                    in {{form.controls[electricalCurrent + '_input_type'].value === 1 ? 'volts' : 'millivolts'}}</mat-hint>
                                                <mat-error>A value between 0 and 10,000 is required</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="form.controls.shunt_enabled.value"
                                             class="shunt-input-container mt-4">
                                            <div class="flex flex-row">
                                                <mat-label class="font-semibold">Amps Scaled</mat-label>
                                                <pending-indicator
                                                    [show]="pendingAnalogReading.scratchpad_2.shunt_amps_scaled"></pending-indicator>
                                            </div>
                                            <mat-form-field class="w-full">
                                                <!-- Decrement button -->
                                                <button id="shunt-amps-decrement" mat-icon-button matPrefix
                                                        type="button"
                                                        longPress (onLongPressing)="decrement('shunt_amps_scaled',0)"
                                                        (click)="decrement('shunt_amps_scaled',0)">
                                                    <mat-icon class="icon-size-5"
                                                              [svgIcon]="'mat_solid:remove'"></mat-icon>
                                                </button>
                                                <!-- input -->
                                                <input id="shunt-amps-input" class="text-center"
                                                       [formControlName]="'shunt_amps_scaled'"
                                                       matInput mask="separator.2" thousandSeparator=","
                                                       [allowNegativeNumbers]="true"
                                                       type="textbox" inputmode="decimal" [placeholder]="'0'">
                                                <!-- Increment button -->
                                                <button id="shunt-amps-increment" mat-icon-button matSuffix
                                                        type="button"
                                                        longPress
                                                        (onLongPressing)="increment('shunt_amps_scaled', 1000)"
                                                        (click)="increment('shunt_amps_scaled',1000)">
                                                    <mat-icon class="icon-size-5"
                                                              [svgIcon]="'mat_solid:add'"></mat-icon>
                                                </button>
                                                <mat-hint>The channel's amps input</mat-hint>
                                                <mat-error>A value between 0 and 1,000 is required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!------------------------------------------------------------>
                    <!-- AC Alarm Settings -->
                    <!------------------------------------------------------------>
                    <div *ngIf="electricalCurrent === 'ac'" class="pb-4">
                        <div style="display:  relative;" class="pt-4 pl-6 pr-6 pb-3 shadow rounded-2xl bg-white">
                            <div matTooltip="Warning!
                            Enabling the alarm feature may significantly reduce the battery life of your device.
                            See device documentation for more information." [matTooltipDisabled]="true" matTooltipPosition="left" #batteryAlarmTooltip="matTooltip" class="flex flex-row items-center">
                                <mat-icon class="icon-size-7 text-current"
                                          [svgIcon]="'mat_solid:notifications_active'"></mat-icon>
                                <div class="ml-3 text-2xl font-bold tracking-tight">Alarm Settings</div>
                            </div>
                            <div class="flex flex-col pb-2">

                                <!------------------------------------------------------------>
                                <!-- High Alarm Enabled -->
                                <!------------------------------------------------------------>
                                <div id="ac-high-alarm-enabled-container" class="flex flex-col pt-2">
                                    <div class="flex flex-row">
                                        <mat-slide-toggle id="ac-high-alarm-toggle"
                                                          formControlName="hi_alarm_enable_ac_73"
                                                          color="primary">
                                            {{form.controls['hi_alarm_enable_ac_73'].value === false ? 'High Alarm Disabled' : 'High Alarm Enabled'}}
                                        </mat-slide-toggle>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['hi_alarm_enable_ac_73']"></pending-indicator>
                                    </div>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- High Alarm Threshold -->
                                <!------------------------------------------------------------>
                                <div
                                    *ngIf="form.controls['hi_alarm_enable_ac_73'].value"
                                    class="high-alarm-threshold-container mt-4">
                                    <div class="flex flex-row">
                                        <mat-label class="font-semibold">High Alarm Threshold</mat-label>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['hi_alarm_threshold_ac_71']"></pending-indicator>
                                    </div>

                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="ac-high-alarm-threshold-decrement" mat-icon-button matPrefix
                                                type="button"
                                                longPress
                                                (onLongPressing)="decrement('hi_alarm_threshold_ac_71', getMinMaxThreshold('hi').min)"
                                                (click)="decrement('hi_alarm_threshold_ac_71',getMinMaxThreshold('hi').min)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- input -->
                                        <input id="ac-high-alarm-threshold-input" class="text-center"
                                               [formControlName]="'hi_alarm_threshold_ac_71'"
                                               matInput mask="separator.2" thousandSeparator=","
                                               [allowNegativeNumbers]="true"
                                               type="textbox" inputmode="decimal" [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="ac-high-alarm-threshold-increment" mat-icon-button matSuffix
                                                type="button"
                                                longPress
                                                (onLongPressing)="increment('hi_alarm_threshold_ac_71', getMinMaxThreshold('hi').max)"
                                                (click)="increment('hi_alarm_threshold_ac_71', getMinMaxThreshold('hi').max)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>Sets the amount of time for significant motion before the alert is
                                            activated on the high end
                                        </mat-hint>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_threshold_ac_71']?.errors?.valueRequired">
                                            {{form.controls['hi_alarm_threshold_ac_71']?.errors?.valueRequired}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_threshold_ac_71']?.errors?.minMaxExceeded">
                                            {{form.controls['hi_alarm_threshold_ac_71']?.errors?.minMaxExceeded}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_threshold_ac_71']?.errors?.lessThanGreaterThanError">
                                            {{form.controls['hi_alarm_threshold_ac_71']?.errors?.lessThanGreaterThanError}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_threshold_ac_71']?.errors?.thresholdAndDeadbandMinMaxError">
                                            {{form.controls['hi_alarm_threshold_ac_71']?.errors?.thresholdAndDeadbandMinMaxError}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- High Alarm Deadband -->
                                <!------------------------------------------------------------>
                                <div
                                    *ngIf="form.controls['hi_alarm_enable_ac_73'].value"
                                    class="high-alarm-deadband-container mt-4">
                                    <div class="flex flex-row">
                                        <mat-label class="font-semibold">High Alarm Deadband</mat-label>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['hi_alarm_deadband_ac_72']"></pending-indicator>
                                    </div>

                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="ac-high-alarm-deadband-decrement" mat-icon-button matPrefix
                                                type="button"
                                                longPress
                                                (onLongPressing)="decrement('hi_alarm_deadband_ac_72', getMinMaxThreshold('hi').min)"
                                                (click)="decrement('hi_alarm_deadband_ac_72',getMinMaxThreshold('hi').min)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- input -->
                                        <input id="ac-high-alarm-deadband-input" class="text-center"
                                               [formControlName]="'hi_alarm_deadband_ac_72'"
                                               matInput mask="separator.2" thousandSeparator=","
                                               [allowNegativeNumbers]="true"
                                               type="textbox" inputmode="decimal" [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="ac-high-alarm-deadband-increment" mat-icon-button matSuffix
                                                type="button"
                                                longPress
                                                (onLongPressing)="increment('hi_alarm_deadband_ac_72', getMinMaxThreshold('hi').max)"
                                                (click)="increment('hi_alarm_deadband_ac_72', getMinMaxThreshold('hi').max)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>Helps prevent chattering by providing some slack.</mat-hint>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_deadband_ac_72']?.errors?.valueRequired">
                                            {{form.controls['hi_alarm_deadband_ac_72']?.errors?.valueRequired}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_deadband_ac_72']?.errors?.minMaxExceeded">
                                            {{form.controls['hi_alarm_deadband_ac_72']?.errors?.minMaxExceeded}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_deadband_ac_72']?.errors?.lessThanGreaterThanError">
                                            {{form.controls['hi_alarm_deadband_ac_72']?.errors?.lessThanGreaterThanError}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_deadband_ac_72']?.errors?.thresholdAndDeadbandMinMaxError">
                                            {{form.controls['hi_alarm_deadband_ac_72']?.errors?.thresholdAndDeadbandMinMaxError}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="section-divider pt-3 pb-3">
                                    <mat-divider></mat-divider>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Low Alarm Enabled -->
                                <!------------------------------------------------------------>
                                <div id="ac-low-alarm-enabled-container" class="flex flex-col pt-2">
                                    <div class="flex flex-row">
                                        <mat-slide-toggle id="ac-low-alarm-toggle"
                                                          formControlName="lo_alarm_enable_ac_79"
                                                          color="primary">
                                            {{form.controls['lo_alarm_enable_ac_79'].value === false ? 'Low Alarm Disabled' : 'Low Alarm Enabled'}}
                                        </mat-slide-toggle>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['lo_alarm_enable_ac_79']"></pending-indicator>
                                    </div>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Low Alarm Threshold -->
                                <!------------------------------------------------------------>
                                <div *ngIf="form.controls['lo_alarm_enable_ac_79'].value"
                                     class="low-alarm-threshold-container mt-4">
                                    <div class="flex flex-row">
                                        <mat-label class="font-semibold">Low Alarm Threshold</mat-label>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['lo_alarm_threshold_ac_77']"></pending-indicator>
                                    </div>
                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="ac-low-alarm-threshold-decrement" mat-icon-button matPrefix
                                                type="button"
                                                longPress
                                                (onLongPressing)="decrement('lo_alarm_threshold_ac_77', getMinMaxThreshold('lo').min)"
                                                (click)="decrement('lo_alarm_threshold_ac_77', getMinMaxThreshold('lo').min)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- input -->
                                        <input id="ac-low-alarm-threshold-input" class="text-center"
                                               formControlName="lo_alarm_threshold_ac_77"
                                               matInput mask="separator.2" thousandSeparator=","
                                               [allowNegativeNumbers]="true"
                                               type="textbox" inputmode="decimal" [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="ac-low-alarm-threshold-increment" mat-icon-button matSuffix
                                                type="button"
                                                longPress
                                                (onLongPressing)="increment('lo_alarm_threshold_ac_77', getMinMaxThreshold('lo').max)"
                                                (click)="increment('lo_alarm_threshold_ac_77', getMinMaxThreshold('lo').max)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>Sets the amount of time for significant motion before the alert is
                                            activated on the low end
                                        </mat-hint>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_threshold_ac_77']?.errors?.valueRequired">
                                            {{form.controls['lo_alarm_threshold_ac_77']?.errors?.valueRequired}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_threshold_ac_77']?.errors?.minMaxExceeded">
                                            {{form.controls['lo_alarm_threshold_ac_77']?.errors?.minMaxExceeded}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_threshold_ac_77']?.errors?.lessThanGreaterThanError">
                                            {{form.controls['lo_alarm_threshold_ac_77']?.errors?.lessThanGreaterThanError}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_threshold_ac_77']?.errors?.thresholdAndDeadbandMinMaxError">
                                            {{form.controls['lo_alarm_threshold_ac_77']?.errors?.thresholdAndDeadbandMinMaxError}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Low Alarm Deadband -->
                                <!------------------------------------------------------------>
                                <div
                                    *ngIf="form.controls['lo_alarm_enable_ac_79'].value"
                                    class="low-alarm-deadband-container mt-4">
                                    <div class="flex flex-row">
                                        <mat-label class="font-semibold">Low Alarm Deadband</mat-label>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['lo_alarm_deadband_ac_78']"></pending-indicator>
                                    </div>

                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="ac-low-alarm-deadband-decrement" mat-icon-button matPrefix
                                                type="button"
                                                longPress
                                                (onLongPressing)="decrement('lo_alarm_deadband_ac_78', getMinMaxThreshold('hi').min)"
                                                (click)="decrement('lo_alarm_deadband_ac_78',getMinMaxThreshold('hi').min)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- input -->
                                        <input id="ac-low-alarm-deadband-input" class="text-center"
                                               [formControlName]="'lo_alarm_deadband_ac_78'"
                                               matInput mask="separator.2" thousandSeparator=","
                                               [allowNegativeNumbers]="true"
                                               type="textbox" inputmode="decimal" [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="ac-low-alarm-deadband-increment" mat-icon-button matSuffix
                                                type="button"
                                                longPress
                                                (onLongPressing)="increment('lo_alarm_deadband_ac_78', getMinMaxThreshold('hi').max)"
                                                (click)="increment('lo_alarm_deadband_ac_78', getMinMaxThreshold('hi').max)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>Helps prevent chattering by providing some slack.</mat-hint>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_deadband_ac_78']?.errors?.valueRequired">
                                            {{form.controls['lo_alarm_deadband_ac_78']?.errors?.valueRequired}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_deadband_ac_78']?.errors?.minMaxExceeded">
                                            {{form.controls['lo_alarm_deadband_ac_78']?.errors?.minMaxExceeded}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_deadband_ac_78']?.errors?.lessThanGreaterThanError">
                                            {{form.controls['lo_alarm_deadband_ac_78']?.errors?.lessThanGreaterThanError}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_deadband_ac_78']?.errors?.thresholdAndDeadbandMinMaxError">
                                            {{form.controls['lo_alarm_deadband_ac_78']?.errors?.thresholdAndDeadbandMinMaxError}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="section-divider pt-3 pb-3">
                                    <mat-divider></mat-divider>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Alarm Persistence -->
                                <!------------------------------------------------------------>
                                <div class="alarm-persistence-container mt-4">
                                    <div class="flex flex-row">
                                        <mat-label class="font-semibold">Alarm Persistence</mat-label>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['alarm_persistence_ac_70']"></pending-indicator>
                                    </div>
                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="ac-alarm-persistence-decrement" mat-icon-button matPrefix
                                                type="button"
                                                longPress
                                                (onLongPressing)="decrement('alarm_persistence_ac_70',getMinMaxPersistence().min)"
                                                (click)="decrement('alarm_persistence_ac_70',getMinMaxPersistence().min)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- input -->
                                        <input id="ac-alarm-persistence-input" class="text-center"
                                               formControlName="alarm_persistence_ac_70"
                                               matInput mask="separator.2" thousandSeparator="," required
                                               type="textbox" inputmode="decimal" [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="ac-alarm-persistence-increment" mat-icon-button matSuffix
                                                type="button"
                                                longPress
                                                (onLongPressing)="increment('alarm_persistence_ac_70',getMinMaxPersistence().max)"
                                                (click)="increment('alarm_persistence_ac_70',getMinMaxPersistence().max)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>The length of time the alarm should persist in minutes</mat-hint>
                                        <mat-error
                                            *ngIf="form.controls['alarm_persistence_ac_70'].value === ''">
                                            You must provide a value
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['alarm_persistence_ac_70']?.errors?.minMaxExceeded === true">
                                            Value must be between {{getMinMaxPersistence().min | mask: 'separator':','}}
                                            and {{getMinMaxPersistence().max | mask: 'separator':','}} minutes
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!------------------------------------------------------------>
                    <!-- DC Alarm Settings -->
                    <!------------------------------------------------------------>
                    <div *ngIf="electricalCurrent === 'dc'" class="pb-4">
                        <div class="pt-4 pl-6 pr-6 pb-3 shadow rounded-2xl bg-white">
                            <div matTooltip="Warning!
                            Enabling the alarm feature may significantly reduce the battery life of your device.
                            See device documentation for more information." [matTooltipDisabled]="true" matTooltipPosition="left" #batteryAlarmTooltip="matTooltip" class="flex flex-row items-center">
                                <mat-icon class="icon-size-7 text-current"
                                          [svgIcon]="'mat_solid:notifications_active'"></mat-icon>
                                <div class="ml-3 text-2xl font-bold tracking-tight">Alarm Settings</div>
                            </div>
                            <div class="flex flex-col pb-2">

                                <!------------------------------------------------------------>
                                <!-- High Alarm Enabled -->
                                <!------------------------------------------------------------>
                                <div id="dc-high-alarm-enabled-container" class="flex flex-col pt-2">
                                    <div class="flex flex-row">
                                        <mat-slide-toggle id="dc-high-alarm-toggle"
                                                          formControlName="hi_alarm_enable_dc_53"
                                                          color="primary">
                                            {{form.controls['hi_alarm_enable_dc_53'].value === false ? 'High Alarm Disabled' : 'High Alarm Enabled'}}
                                        </mat-slide-toggle>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['hi_alarm_enable_dc_53']"></pending-indicator>
                                    </div>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- High Alarm Threshold -->
                                <!------------------------------------------------------------>
                                <div
                                    *ngIf="form.controls['hi_alarm_enable_dc_53'].value"
                                    class="high-alarm-threshold-container mt-4">
                                    <div class="flex flex-row">
                                        <mat-label class="font-semibold">High Alarm Threshold</mat-label>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['hi_alarm_threshold_dc_51']"></pending-indicator>
                                    </div>

                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="dc-high-alarm-threshold-decrement" mat-icon-button matPrefix
                                                type="button"
                                                longPress
                                                (onLongPressing)="decrement('hi_alarm_threshold_dc_51', getMinMaxThreshold('hi').min)"
                                                (click)="decrement('hi_alarm_threshold_dc_51',getMinMaxThreshold('hi').min)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- input -->
                                        <input id="dc-high-alarm-threshold-input" class="text-center"
                                               [formControlName]="'hi_alarm_threshold_dc_51'"
                                               matInput mask="separator.2" thousandSeparator=","
                                               [allowNegativeNumbers]="true"
                                               type="textbox" inputmode="decimal" [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="dc-high-alarm-threshold-increment" mat-icon-button matSuffix
                                                type="button"
                                                longPress
                                                (onLongPressing)="increment('hi_alarm_threshold_dc_51', getMinMaxThreshold('hi').max)"
                                                (click)="increment('hi_alarm_threshold_dc_51', getMinMaxThreshold('hi').max)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>The channel's high alarm threshold
                                            in {{form.controls[electricalCurrent + '_input_type'].value === 1 ? 'volts' : 'millivolts'}}</mat-hint>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_threshold_dc_51']?.errors?.valueRequired">
                                            {{form.controls['hi_alarm_threshold_dc_51']?.errors?.valueRequired}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_threshold_dc_51']?.errors?.minMaxExceeded">
                                            {{form.controls['hi_alarm_threshold_dc_51']?.errors?.minMaxExceeded}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_threshold_dc_51']?.errors?.lessThanGreaterThanError">
                                            {{form.controls['hi_alarm_threshold_dc_51']?.errors?.lessThanGreaterThanError}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_threshold_dc_51']?.errors?.thresholdAndDeadbandMinMaxError">
                                            {{form.controls['hi_alarm_threshold_dc_51']?.errors?.thresholdAndDeadbandMinMaxError}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- High Alarm Deadband -->
                                <!------------------------------------------------------------>
                                <div
                                    *ngIf="form.controls['hi_alarm_enable_dc_53'].value"
                                    class="high-alarm-deadband-container mt-4">
                                    <div class="flex flex-row">
                                        <mat-label class="font-semibold">High Alarm Deadband</mat-label>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['hi_alarm_deadband_dc_52']"></pending-indicator>
                                    </div>

                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="dc-high-alarm-deadband-decrement" mat-icon-button matPrefix
                                                type="button"
                                                longPress
                                                (onLongPressing)="decrement('hi_alarm_deadband_dc_52', getMinMaxThreshold('hi').min)"
                                                (click)="decrement('hi_alarm_deadband_dc_52',getMinMaxThreshold('hi').min)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- input -->
                                        <input id="dc-high-alarm-deadband-input" class="text-center"
                                               [formControlName]="'hi_alarm_deadband_dc_52'"
                                               matInput mask="separator.2" thousandSeparator=","
                                               [allowNegativeNumbers]="true"
                                               type="textbox" inputmode="decimal" [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="dc-high-alarm-deadband-increment" mat-icon-button matSuffix
                                                type="button"
                                                longPress
                                                (onLongPressing)="increment('hi_alarm_deadband_dc_52', getMinMaxThreshold('hi').max)"
                                                (click)="increment('hi_alarm_deadband_dc_52', getMinMaxThreshold('hi').max)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>Helps prevent chattering by providing some slack.</mat-hint>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_deadband_dc_52']?.errors?.valueRequired">
                                            {{form.controls['hi_alarm_deadband_dc_52']?.errors?.valueRequired}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_deadband_dc_52']?.errors?.minMaxExceeded">
                                            {{form.controls['hi_alarm_deadband_dc_52']?.errors?.minMaxExceeded}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_deadband_dc_52']?.errors?.lessThanGreaterThanError">
                                            {{form.controls['hi_alarm_deadband_dc_52']?.errors?.lessThanGreaterThanError}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['hi_alarm_deadband_dc_52']?.errors?.thresholdAndDeadbandMinMaxError">
                                            {{form.controls['hi_alarm_deadband_dc_52']?.errors?.thresholdAndDeadbandMinMaxError}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="section-divider pt-3 pb-3">
                                    <mat-divider></mat-divider>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Low Alarm Enabled -->
                                <!------------------------------------------------------------>
                                <div id="dc-low-alarm-enabled-container" class="flex flex-col pt-2">
                                    <div class="flex flex-row">
                                        <mat-slide-toggle id="dc-low-alarm-toggle"
                                                          formControlName="lo_alarm_enable_dc_59"
                                                          color="primary">
                                            {{form.controls['lo_alarm_enable_dc_59'].value === false ? 'Low Alarm Disabled' : 'Low Alarm Enabled'}}
                                        </mat-slide-toggle>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['lo_alarm_enable_dc_59']"></pending-indicator>
                                    </div>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Low Alarm Threshold -->
                                <!------------------------------------------------------------>
                                <div *ngIf="form.controls['lo_alarm_enable_dc_59'].value"
                                     class="low-alarm-threshold-container mt-4">
                                    <div class="flex flex-row">
                                        <mat-label class="font-semibold">Low Alarm Threshold</mat-label>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['lo_alarm_threshold_dc_57']"></pending-indicator>
                                    </div>
                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="dc-low-alarm-threshold-decrement" mat-icon-button matPrefix
                                                type="button"
                                                longPress
                                                (onLongPressing)="decrement('lo_alarm_threshold_dc_57', getMinMaxThreshold('lo').min)"
                                                (click)="decrement('lo_alarm_threshold_dc_57', getMinMaxThreshold('lo').min)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- input -->
                                        <input id="dc-low-alarm-threshold-input" class="text-center"
                                               formControlName="lo_alarm_threshold_dc_57"
                                               matInput mask="separator.2" thousandSeparator=","
                                               [allowNegativeNumbers]="true"
                                               type="textbox" inputmode="decimal" [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="dc-low-alarm-threshold-increment" mat-icon-button matSuffix
                                                type="button"
                                                longPress
                                                (onLongPressing)="increment('lo_alarm_threshold_dc_57', getMinMaxThreshold('lo').max)"
                                                (click)="increment('lo_alarm_threshold_dc_57', getMinMaxThreshold('lo').max)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>The channel's low alarm threshold
                                            in {{form.controls[electricalCurrent + '_input_type'].value === 1 ? 'volts' : 'millivolts'}}</mat-hint>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_threshold_dc_57']?.errors?.valueRequired">
                                            {{form.controls['lo_alarm_threshold_dc_57']?.errors?.valueRequired}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_threshold_dc_57']?.errors?.minMaxExceeded">
                                            {{form.controls['lo_alarm_threshold_dc_57']?.errors?.minMaxExceeded}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_threshold_dc_57']?.errors?.lessThanGreaterThanError">
                                            {{form.controls['lo_alarm_threshold_dc_57']?.errors?.lessThanGreaterThanError}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_threshold_dc_57']?.errors?.thresholdAndDeadbandMinMaxError">
                                            {{form.controls['lo_alarm_threshold_dc_57']?.errors?.thresholdAndDeadbandMinMaxError}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Low Alarm Deadband -->
                                <!------------------------------------------------------------>
                                <div
                                    *ngIf="form.controls['lo_alarm_enable_dc_59'].value"
                                    class="low-alarm-deadband-container mt-4">
                                    <div class="flex flex-row">
                                        <mat-label class="font-semibold">Low Alarm Deadband</mat-label>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['lo_alarm_deadband_dc_58']"></pending-indicator>
                                    </div>

                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="dc-low-alarm-deadband-decrement" mat-icon-button matPrefix
                                                type="button"
                                                longPress
                                                (onLongPressing)="decrement('lo_alarm_deadband_dc_58', getMinMaxThreshold('hi').min)"
                                                (click)="decrement('lo_alarm_deadband_dc_58',getMinMaxThreshold('hi').min)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- input -->
                                        <input id="dc-low-alarm-deadband-input" class="text-center"
                                               [formControlName]="'lo_alarm_deadband_dc_58'"
                                               matInput mask="separator.2" thousandSeparator=","
                                               [allowNegativeNumbers]="true"
                                               type="textbox" inputmode="decimal" [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="dc-low-alarm-deadband-increment" mat-icon-button matSuffix
                                                type="button"
                                                longPress
                                                (onLongPressing)="increment('lo_alarm_deadband_dc_58', getMinMaxThreshold('hi').max)"
                                                (click)="increment('lo_alarm_deadband_dc_58', getMinMaxThreshold('hi').max)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>Helps prevent chattering by providing some slack.</mat-hint>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_deadband_dc_58']?.errors?.valueRequired">
                                            {{form.controls['lo_alarm_deadband_dc_58']?.errors?.valueRequired}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_deadband_dc_58']?.errors?.minMaxExceeded">
                                            {{form.controls['lo_alarm_deadband_dc_58']?.errors?.minMaxExceeded}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls.lo_alarm_deadband_dc_58?.errors?.lessThanGreaterThanError">
                                            {{form.controls.lo_alarm_deadband_dc_58.errors.lessThanGreaterThanError}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['lo_alarm_deadband_dc_58']?.errors?.thresholdAndDeadbandMinMaxError">
                                            {{form.controls['lo_alarm_deadband_dc_58']?.errors?.thresholdAndDeadbandMinMaxError}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="section-divider pt-3 pb-3">
                                    <mat-divider></mat-divider>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Alarm Persistence -->
                                <!------------------------------------------------------------>
                                <div class="alarm-persistence-container mt-4">
                                    <div class="flex flex-row">
                                        <mat-label class="font-semibold">Alarm Persistence</mat-label>
                                        <pending-indicator
                                            [show]="pendingAnalogReading['alarm_persistence_dc_50']"></pending-indicator>
                                    </div>
                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="dc-alarm-persistence-decrement" mat-icon-button matPrefix
                                                type="button"
                                                longPress
                                                (onLongPressing)="decrement('alarm_persistence_dc_50',getMinMaxPersistence().min)"
                                                (click)="decrement('alarm_persistence_dc_50',getMinMaxPersistence().min)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- input -->
                                        <input id="dc-alarm-persistence-input" class="text-center"
                                               formControlName="alarm_persistence_dc_50"
                                               matInput mask="separator.2" thousandSeparator="," required
                                               type="textbox" inputmode="decimal" [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="dc-alarm-persistence-increment" mat-icon-button matSuffix
                                                type="button"
                                                longPress
                                                (onLongPressing)="increment('alarm_persistence_dc_50',getMinMaxPersistence().max)"
                                                (click)="increment('alarm_persistence_dc_50',getMinMaxPersistence().max)">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>The length of time the alarm should persist in minutes</mat-hint>
                                        <mat-error
                                            *ngIf="form.controls['alarm_persistence_dc_50'].value === ''">
                                            You must provide a value
                                        </mat-error>
                                        <mat-error
                                            *ngIf="form.controls['alarm_persistence_dc_50']?.errors?.minMaxExceeded === true">
                                            Value must be between {{getMinMaxPersistence().min | mask: 'separator':','}}
                                            and {{getMinMaxPersistence().max | mask: 'separator':','}} minutes
                                        </mat-error>
                                    </mat-form-field>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!------------------------------------------------------------>
            <!-- Digital Form -->
            <!------------------------------------------------------------>
            <div *ngIf="!isAnalog" class="flex flex-col pl-3 pr-3 mt-4">
                <!------------------------------------------------------------>
                <!-- Display Settings -->
                <!------------------------------------------------------------>
                <div *ngIf="form.controls.enabled.value" class="pb-4">
                    <div class="pt-4 pl-6 pr-6 pb-3 shadow rounded-2xl bg-white">
                        <div class="flex flex-row items-center">
                            <mat-icon class="icon-size-7 text-current" [svgIcon]="'mat_solid:monitor'"></mat-icon>
                            <div class="ml-3 text-2xl font-bold tracking-tight">Display Settings</div>
                        </div>
                        <div class="flex flex-col pt-2 pb-2">
                            <!------------------------------------------------------------>
                            <!-- Label -->
                            <!------------------------------------------------------------>
                            <div class="input-container flex flex-col">
                                <mat-form-field
                                    class="flex-auto gt-xs:pr-3">
                                    <mat-label>Label</mat-label>
                                    <input matInput formControlName="display_unit_label" [maxlength]="20">
                                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'mat_solid:memory'"></mat-icon>
                                    <mat-error>Input name must be between 1 and 20 characters</mat-error>
                                    <mat-hint>Provide a label for the channel</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <!------------------------------------------------------------>
                <!-- Digital Accumulator Settings -->
                <!------------------------------------------------------------>
                <div *ngIf="form.controls.enabled.value" class="pb-4">
                    <div class="pt-4 pl-6 pr-6 pb-3 shadow rounded-2xl bg-white">

                        <!------------------------------------------------------------>
                        <!-- Card Title -->
                        <!------------------------------------------------------------>
                        <div class="flex flex-row items-center">
                            <mat-icon class="icon-size-7 text-current"
                                      [svgIcon]="'mat_solid:battery_charging_full'"></mat-icon>
                            <div class="ml-3 text-2xl font-bold tracking-tight">Accumulator Settings</div>
                        </div>

                        <!------------------------------------------------------------>
                        <!-- Enable Toggle -->
                        <!------------------------------------------------------------>
                        <div class="flex flex-col pt-2">
                            <mat-slide-toggle id="digital-accumulator-enabled-toggle"
                                              formControlName="accumulator_enable_7"
                                              color="primary">
                                <div class="flex flex-row">
                                    {{form.controls?.accumulator_enable_7?.value === false ? 'Accumulator Disabled' : 'Accumulator Enabled'}}
                                </div>
                            </mat-slide-toggle>
                        </div>

                        <!------------------------------------------------------------>
                        <!-- Accumulator Container -->
                        <!------------------------------------------------------------>
                        <div *ngIf="form.controls?.accumulator_enable_7?.value === true">
                            <!------------------------------------------------------------>
                            <!-- Pulse Count -->
                            <!------------------------------------------------------------>
                            <div class="digital-accumulator-counter-container mt-4">
                                <div class="flex flex-row">
                                    <mat-label class="font-semibold">Count</mat-label>
                                    <pending-indicator [show]="pendingDigitalReading.count_10"></pending-indicator>
                                </div>
                                <!-- Form Field -->
                                <mat-form-field class="w-full">
                                    <!-- Decrement button -->
                                    <button id="digital-accumulator-count-decrement" mat-icon-button matPrefix
                                            type="button"
                                            longPress (onLongPressing)="decrement('count_10',0)"
                                            (click)="decrement('count_10',0)">
                                        <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                    </button>
                                    <!-- input -->
                                    <input id="digital-accumulator-count-input" class="text-center"
                                           formControlName="count_10" matInput mask="separator.2"
                                           thousandSeparator=","
                                           type="textbox" inputmode="decimal" [placeholder]="'0'">
                                    <!-- Increment button -->
                                    <button id="digital-accumulator-count-increment" mat-icon-button matSuffix
                                            type="button"
                                            longPress (onLongPressing)="increment('count_10',999999999)"
                                            (click)="increment('count_10',999999999)">
                                        <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                    </button>
                                    <mat-hint>The number of times a current is applied</mat-hint>
                                    <mat-error *ngIf="form?.controls?.count_10.value === ''">You must provide a
                                        value
                                    </mat-error>
                                    <mat-error *ngIf="form?.controls?.count_10.value > 999999999">Value cannot be
                                        greater than 999,999,999
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <!------------------------------------------------------------>
                            <!-- Accumulator Alarm Toggle & Alarm Value Input Container -->
                            <!------------------------------------------------------------>
                            <div class="alarm-enabled-and-input-container">
                                <!------------------------------------------------------------>
                                <!-- Enable Toggle -->
                                <!------------------------------------------------------------>
                                <div class="flex flex-col pt-2">
                                    <mat-slide-toggle id="digital-accumulator-count-alarm-enabled-toggle"
                                                      formControlName="count_alarm_enable_58"
                                                      color="primary">
                                        <div class="flex flex-row">
                                            {{form?.controls?.count_alarm_enable_58?.value === false ||
                                        form?.controls?.count_alarm_enable_58?.value === 0 ? 'Alarm Disabled' : 'Alarm Enabled'}}
                                            <pending-indicator
                                                [show]="pendingDigitalReading.count_alarm_enable_58"></pending-indicator>
                                        </div>
                                    </mat-slide-toggle>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Accumulator Alarm Container -->
                                <!------------------------------------------------------------>
                                <div *ngIf="form?.controls?.count_alarm_enable_58?.value === true ||
                                        form?.controls?.count_alarm_enable_58?.value === 1">
                                    <!------------------------------------------------------------>
                                    <!-- Alarm Count -->
                                    <!------------------------------------------------------------>
                                    <div class="digital-accumulator-alarm-count-container mt-4">
                                        <div class="flex flex-row">
                                            <mat-label class="font-semibold">Alarm Value</mat-label>
                                            <pending-indicator
                                                [show]="pendingDigitalReading.count_alarm_value_57"></pending-indicator>
                                        </div>
                                        <!-- Form Field -->
                                        <mat-form-field class="w-full">
                                            <!-- Decrement button -->
                                            <button id="digital-accumulator-alarm-count-decrement" mat-icon-button
                                                    matPrefix
                                                    type="button"
                                                    longPress (onLongPressing)="decrement('count_alarm_value_57',0)"
                                                    (click)="decrement('count_alarm_value_57',0)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                            </button>
                                            <!-- input -->
                                            <input id="digital-accumulator-alarm-count-input" class="text-center"
                                                   formControlName="count_alarm_value_57" matInput mask="separator.2"
                                                   thousandSeparator=","
                                                   type="textbox" inputmode="decimal" [placeholder]="'0'">
                                            <!-- Increment button -->
                                            <button id="digital-accumulator-alarm-count-increment" mat-icon-button
                                                    matSuffix
                                                    type="button"
                                                    longPress
                                                    (onLongPressing)="increment('count_alarm_value_57',999999999)"
                                                    (click)="increment('count_alarm_value_57',999999999)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                            </button>
                                            <mat-hint>The threshold value to trigger the alarm</mat-hint>
                                            <mat-error *ngIf="form?.controls?.count_alarm_value_57?.value === ''">You
                                                must
                                                provide a
                                                value
                                            </mat-error>
                                            <mat-error *ngIf="form?.controls?.count_alarm_value_57?.value > 999999999">
                                                Value cannot be
                                                greater than 999,999,999
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <!------------------------------------------------------------>
                            <!--Divider -->
                            <!------------------------------------------------------------>
                            <div class="mt-4 mb-4">
                                <mat-divider></mat-divider>
                            </div>

                            <!------------------------------------------------------------>
                            <!-- Reset Mode Select Box & Reset Inputs -->
                            <!------------------------------------------------------------>
                            <div class="reset-mode-container mt-4">
                                <!------------------------------------------------------------>
                                <!-- Reset Mode -->
                                <!------------------------------------------------------------>
                                <div id="digital-accumulator-rest-mode-container">
                                    <div class="flex flex-col">
                                        <div class="flex flex-row">
                                            <mat-label class="font-semibold">Reset Mode</mat-label>
                                            <pending-indicator
                                                [show]="pendingDigitalReading.reset_mode_11"></pending-indicator>
                                        </div>
                                        <mat-form-field>
                                            <mat-select id="digital-accumulator-rest-mode-select-box"
                                                        formControlName="reset_mode_11"
                                                        placeholder="Select a reset mode">
                                                <mat-option *ngFor="let resetMode of resetModes"
                                                            [value]="resetMode.key">
                                                    {{resetMode.value}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-icon class="icon-size-5" matPrefix
                                                      [svgIcon]="'mat_solid:restart_alt'"></mat-icon>
                                            <mat-hint>Select a reset mode</mat-hint>
                                            <mat-error>You must select a reset mode</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Accumulator Alarm Container -->
                                <!------------------------------------------------------------>
                                <div class="reset-mode-container" *ngIf="form?.controls?.reset_mode_11?.value === 1">
                                    <!------------------------------------------------------------>
                                    <!-- Reset At -->
                                    <!------------------------------------------------------------>
                                    <div class="digital-accumulator-reset-at-container mt-2">
                                        <div class="flex flex-row">
                                            <mat-label class="font-semibold">Reset At</mat-label>
                                            <pending-indicator
                                                [show]="pendingDigitalReading.reset_at_12"></pending-indicator>
                                        </div>
                                        <!-- Form Field -->
                                        <mat-form-field class="w-full">
                                            <!-- Decrement button -->
                                            <button id="digital-accumulator-reset-at-decrement" mat-icon-button
                                                    matPrefix
                                                    type="button"
                                                    longPress (onLongPressing)="decrement('reset_at_12',0)"
                                                    (click)="decrement('reset_at_12',0)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                            </button>
                                            <!-- input -->
                                            <input id="digital-accumulator-reset-at-input" class="text-center"
                                                   formControlName="reset_at_12" matInput mask="separator.2"
                                                   thousandSeparator=","
                                                   type="textbox" inputmode="decimal" [placeholder]="'0'">
                                            <!-- Increment button -->
                                            <button id="digital-accumulator-reset-at-increment" mat-icon-button
                                                    matSuffix
                                                    type="button"
                                                    longPress (onLongPressing)="increment('reset_at_12',999999999)"
                                                    (click)="increment('reset_at_12',999999999)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                            </button>
                                            <mat-hint>The threshold value at which point the count is reset at
                                            </mat-hint>
                                            <mat-error *ngIf="form?.controls?.reset_at_12?.value === ''">You must
                                                provide a value
                                            </mat-error>
                                            <mat-error *ngIf="form?.controls?.reset_at_12?.value > 999999999">Value
                                                cannot be greater than 999,999,999
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!------------------------------------------------------------>
                                    <!-- Reset To -->
                                    <!------------------------------------------------------------>
                                    <div class="digital-accumulator-reset-to-container mt-2">
                                        <div class="flex flex-row">
                                            <mat-label class="font-semibold">Reset To</mat-label>
                                            <pending-indicator
                                                [show]="pendingDigitalReading.reset_to_13"></pending-indicator>
                                        </div>
                                        <!-- Form Field -->
                                        <mat-form-field class="w-full">
                                            <!-- Decrement button -->
                                            <button id="digital-accumulator-reset-to-decrement" mat-icon-button
                                                    matPrefix
                                                    type="button"
                                                    longPress (onLongPressing)="decrement('reset_to_13',0)"
                                                    (click)="decrement('reset_to_13',0)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                            </button>
                                            <!-- input -->
                                            <input id="digital-accumulator-reset-to-input" class="text-center"
                                                   formControlName="reset_to_13" matInput mask="separator.2"
                                                   thousandSeparator=","
                                                   type="textbox" inputmode="decimal" [placeholder]="'0'">
                                            <!-- Increment button -->
                                            <button id="digital-accumulator-reset-to-increment" mat-icon-button
                                                    matSuffix
                                                    type="button"
                                                    longPress (onLongPressing)="increment('reset_to_13',999999999)"
                                                    (click)="increment('reset_to_13',999999999)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                            </button>
                                            <mat-hint>The threshold value at which point the count is reset to
                                            </mat-hint>
                                            <mat-error *ngIf="form?.controls?.reset_to_13?.value === ''">You must
                                                provide a value
                                            </mat-error>
                                            <mat-error *ngIf="form?.controls?.reset_to_13?.value > 999999999">Value
                                                cannot be greater than 999,999,999
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!------------------------------------------------------------>
                <!-- Alarm Settings -->
                <!------------------------------------------------------------>
                <div *ngIf="form.controls.enabled.value" class="pb-4">
                    <div class="pt-4 pl-6 pr-6 pb-3 shadow rounded-2xl bg-white">
                        <div class="flex flex-row items-center">
                            <mat-icon class="icon-size-7 text-current"
                                      [svgIcon]="'mat_solid:notifications_active'"></mat-icon>
                            <div class="ml-3 text-2xl font-bold tracking-tight">Alarm Settings</div>
                        </div>
                        <div class="flex flex-col pb-2">
                            <!------------------------------------------------------------>
                            <!-- High Alarm Enabled -->
                            <!------------------------------------------------------------>
                            <div id="digital-high-alarm-enabled-container" class="flex flex-col pt-2">
                                <mat-slide-toggle id="digital-high-alarm-toggle"
                                                  formControlName="hi_alarm_enabled_51"
                                                  color="primary">

                                    <div class="flex flex-row">
                                        {{form.controls.hi_alarm_enabled_51.value === false || form.controls.hi_alarm_enabled_51.value === 0 ? 'High Alarm Disabled' : 'High Alarm Enabled'}}
                                        <pending-indicator
                                            [show]="pendingDigitalReading.hi_alarm_enabled_51"></pending-indicator>
                                    </div>
                                </mat-slide-toggle>
                            </div>

                            <!------------------------------------------------------------>
                            <!-- Low Alarm Enabled -->
                            <!------------------------------------------------------------>
                            <div id="digital-low-alarm-enabled-container" class="flex flex-col pt-2">
                                <mat-slide-toggle id="digital-low-alarm-toggle"
                                                  formControlName="lo_alarm_enabled_54"
                                                  color="primary">
                                    <div class="flex flex-row">
                                        {{form.controls.lo_alarm_enabled_54.value === false || form.controls.lo_alarm_enabled_54.value === 0 ? 'Low Alarm Disabled' : 'Low Alarm Enabled'}}
                                        <pending-indicator
                                            [show]="pendingDigitalReading.lo_alarm_enabled_54"></pending-indicator>
                                    </div>
                                </mat-slide-toggle>
                            </div>

                            <!------------------------------------------------------------>
                            <!-- Alarm Persistence -->
                            <!------------------------------------------------------------>
                            <div class="alarm-persistence-container mt-4">
                                <div class="flex flex-row">
                                    <mat-label class="font-semibold">Alarm Persistence</mat-label>
                                    <pending-indicator
                                        [show]="pendingDigitalReading.alarm_persistence_50"></pending-indicator>
                                </div>
                                <mat-form-field class="w-full">
                                    <!-- Decrement button -->
                                    <button id="digital-alarm-persistence-decrement" mat-icon-button matPrefix
                                            type="button"
                                            longPress (onLongPressing)="decrement('alarm_persistence_50',1)"
                                            (click)="decrement('alarm_persistence_50',1)">
                                        <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                    </button>
                                    <!-- input -->
                                    <input id="digital-alarm-persistence-input" class="text-center"
                                           formControlName="alarm_persistence_50" matInput mask="separator.2"
                                           thousandSeparator="," required
                                           type="textbox" inputmode="decimal" [placeholder]="'0'">
                                    <!-- Increment button -->
                                    <button id="digital-alarm-persistence-increment" mat-icon-button matSuffix
                                            type="button"
                                            longPress (onLongPressing)="increment('alarm_persistence_50',1000000)"
                                            (click)="increment('alarm_persistence_50',1000000)">
                                        <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                    </button>
                                    <mat-hint>The length of time the alarm should persist in minutes</mat-hint>
                                    <mat-error *ngIf="form.controls.alarm_persistence_50.value === ''">You must provide
                                        a value
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.alarm_persistence_50.value > 1000000">
                                        The max persistence time is 1,000,000 minutes
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <footer class="w-full text-center border-t border-grey p-4">
            <!------------------------------------------------------------>
            <!-- Actions -->
            <!------------------------------------------------------------>
            <div class="w-full max-w-3xl">
                <div class="flex items-center justify-end">
                    <button id="cancel-button" *ngIf="!isSaving" (click)="settingsDrawer.close()" mat-stroked-button
                            type="button"
                            [color]="'accent'">Cancel
                    </button>
                    <saving-button [id]="'channel-settings-save-button'"
                                   [disabled]="form.invalid || form.pristine"
                                   [hasErrors]="form.invalid"
                                   [text]="'Save'"
                                   [textWhenProcessing]="'Saving'"
                                   [isProcessing]="isSaving"
                                   (click)="save()">
                    </saving-button>
                </div>
            </div>
        </footer>
    </div>
</core-drawer>
