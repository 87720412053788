import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApiService, AuthService, BroadcastService, Dictionary, StorageService} from '@ai/ngx-concentric';
import {Unit} from '@ai/ngx-concentric/lib/models/unit';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ScheduleModeEnum} from './reporting.enums';
import {DatePipe} from '@angular/common';
import {ReportingService} from './reporting.service';
import {AlertService} from '../../../core/services/alert.service';
import {UnitService, UnitService as InternalUnitService} from '../../../core/services/unit.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {FormService} from '../../../core/services/form.service';
import {BwctHubService} from '../../../core/services/bwct-hub.service';
import {environment} from '../../../../environments/environment';
import {ReportingFormResponse} from './reporting.models';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { BrandType } from 'app/core/enums/brand-type.enum';

@Component({
    selector: 'reporting',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit, OnDestroy {
    @Input() unitId: string;
    @Input() showBreadcrumb: string = 'true'; // Used for MFE
    @Input() showUnitSearch: string = 'true'; // Used for MFE
    @Input() showCardTitle: string = 'true'; // Used for MFE
    @Input() cardColor: string = '#fff'; // Used for MFE
    public unit: Unit;
    public form: FormGroup;
    public payloadForm: FormGroup;
    public scheduleMode = ScheduleModeEnum;
    public scheduleModes: Dictionary<string, number>[] = [];
    public reportingIntervals: Dictionary<string, number>[] = [];
    public daysOfTheMonth: Dictionary<string, number>[] = [];
    public reportingFormResponse: ReportingFormResponse;
    private _unsubscribeAll: Subject<any> = new Subject();
    public placeHolderText: string;

    constructor(private _activatedRoute: ActivatedRoute,
                private _alertService: AlertService,
                private _apiService: ApiService,
                private _authService: AuthService,
                private _broadcastService: BroadcastService,
                private _bwctHubService: BwctHubService,
                private _changeDetectorRef: ChangeDetectorRef,
                private _datePipe: DatePipe,
                private _formBuilder: FormBuilder,
                private _formService: FormService,
                private _internalUnitService: InternalUnitService,
                public _reportingService: ReportingService,
                public _router: Router,
                private _storageService: StorageService,
                private _unitService: UnitService) {
        _storageService.watch().subscribe((key) => {
            if (key == "selectedUnitId")
                this.unitId = this.unitId ?? _storageService.get(key);
        });
    }

    // -----------------------------------------------------------------
    // On Init
    // -----------------------------------------------------------------
    async ngOnInit(): Promise<void> {
        if (this.unitId) {
            localStorage.setItem('selectedUnitId', this.unitId);
        }
        this.reportingIntervals = await this._reportingService.getReportingIntervalsAsync();
        this.daysOfTheMonth = this._reportingService.getDaysOfMonth();
        this.unit = await this._internalUnitService.getUnitAsync(+this.unitId ?? null, this._activatedRoute);
        await this.buildFormAsync();
        await this.subscribeToBroadcastAsync();
        if (this.unit?.brandId == BrandType.RM540C || this.unit?.brandId == BrandType.RM540S) {
                this.placeHolderText = "Choose up to 2 days of the month";
        } else {
            this.placeHolderText = "Choose up to 4 days of the month";
        }
    }

    // -----------------------------------------------------------------
    // Builds the readings form
    // -----------------------------------------------------------------
    async buildFormAsync(): Promise<void> {
        this.scheduleModes = this._reportingService.getScheduleModes(this.unit.brandId);
        this.reportingIntervals = await this._reportingService.getReportingIntervalsAsync();
        this.reportingFormResponse = await this._reportingService.getFormControlsAsync(this.unit);
        this.form = this._formBuilder.group({
            scheduleMode: [this.reportingFormResponse.ReportingModel.schedule_mode_1, [Validators.required]],
            startDay: [this.reportingFormResponse.ReportingModel.startDay, [Validators.required]],
            startTime: [this.reportingFormResponse.ReportingModel.start_time_2, [Validators.required]],
            reportingInterval: [{value: this.reportingFormResponse.ReportingModel.repeat_interval_3, disabled: this.reportingIntervals.length === 0}],
            daysOfTheMonth: [this.reportingFormResponse.ReportingModel.day_of_month_4],
        });
        this.subscribeToFormChanges();
        console.log("data2",this.reportingFormResponse.ReportingModel.day_of_month_4);
    }

    // -----------------------------------------------------------------
    // Update form validations based on reporting mode
    // -----------------------------------------------------------------
    subscribeToFormChanges(): void {
        this.set(this.form.controls.scheduleMode.value);
        this.form.get('scheduleMode').valueChanges.subscribe((value) => {
            this.set(value);
        });
    }

    // -----------------------------------------------------------------
    // Set the initial form validations
    // -----------------------------------------------------------------
    set(value: ScheduleModeEnum): void {
        if (value === this.scheduleMode.off) {
            this._formService.cleanValidatorsAndUpdateValidity(['reportingInterval', 'daysOfTheMonth'], this.form);
            return;
        }

        if (value === this.scheduleMode.repeat) {
            this._formService.setValidatorsAndUpdateValidity(['reportingInterval'], this.form, Validators.required);
            this._formService.cleanValidatorsAndUpdateValidity(['daysOfTheMonth'], this.form);
            return;
        }

        if (value === this.scheduleMode.dayOfMonth) {
            this._formService.cleanValidatorsAndUpdateValidity(['reportingInterval'], this.form);
            this._formService.setValidatorsAndUpdateValidity(['daysOfTheMonth'], this.form, Validators.required);
        }
    }

    // -----------------------------------------------------------------
    // Determines which options should be disabled based on limit
    // -----------------------------------------------------------------
    isOptionDisabled(opt: any): boolean {
        const days = this.form.controls.daysOfTheMonth.value;
        const isRM540 = this.isRM540Brand();
    
        if (isRM540) {
            return this.isRM540OptionDisabled(days, opt);
        } 
        return this.isOtherOptionDisabled(days, opt);
    }

    private isRM540Brand(): boolean {
        return this.unit?.brandId === BrandType.RM540C || this.unit?.brandId === BrandType.RM540S;
    }
    
    private isRM540OptionDisabled(days: number[], opt: any): boolean {
        const filteredZero = this.filterZeroDays(days);
        return (days[0] !== 0 && days[1] !== 0) && (days.length >= 2 && !filteredZero.includes(opt));
    }
    
    private isOtherOptionDisabled(days: number[], opt: any): boolean {
        return days.length >= 4 && !days.includes(opt);
    }
    
    private filterZeroDays(days: number[]): number[] {
        return days.filter(x => x !== 0);
    }

    // End of private method for isOptionDisabled
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // Saves the readings form
    // -----------------------------------------------------------------
    async saveSettingsAsync(): Promise<void> {
        // Build payload form based on dirty controls
        this.payloadForm = this._formBuilder.group({});
        Object.keys(this.form.controls).forEach((key) => {
            this.form.controls[key].markAsTouched();
            if (this.form.controls[key].dirty) {
                this.payloadForm.addControl(key, this.form.controls[key]);
            }
            if (!this.form.controls[key].valid) {
                throw new Error(`${key} is invalid`);
            }
        });

        // populate index 2 and 3 of days of month for RM540
        if (this.unit?.brandId == BrandType.RM540C || this.unit?.brandId == BrandType.RM540S) {
            this.setDaysOfMonthDataForRM540();
        }

        // If no changes were made
        if (Object.keys(this.payloadForm.controls).length === 0) {
            this._alertService.info(`No changes were made to ${this.unit.clientUnitName}`);
            return;
        }

        // Create request
        const payloadRequest = JSON.stringify(this._reportingService.buildJsonPayload(this.form, this.payloadForm, this.unit));

        // Submit request
        await this._apiService.putAsync(`${environment.bullhornApiUrl}/Ota/SendOtaRequestAsync`, {
            deviceId: this.unit.streamId,
            request: payloadRequest,
        }).then(async () => {
            this._alertService.success(`${this.unit.clientUnitName} has been updated`);
        });
    }

    // -----------------------------------------------------------------
    // Populate index 2 and 3 of days of month for RM540
    // -----------------------------------------------------------------
    setDaysOfMonthDataForRM540() {
        Object.keys(this.payloadForm.controls).forEach((key) => {
            if (key == 'daysOfTheMonth') {
                let daysOfTheMonth: number[] = this.payloadForm.controls[key].value;
                if (daysOfTheMonth.length == 1) {
                    daysOfTheMonth.push(0);
                }
                daysOfTheMonth.push(0);
                daysOfTheMonth.push(0);
                this.payloadForm.controls[key].setValue(daysOfTheMonth);
            }
        });
    }

    // -----------------------------------------------------------------
    // Subscribe To Broadcast events
    // -----------------------------------------------------------------
    subscribeToBroadcastAsync(): void {
        this._broadcastService.subscribe('search:unitSelected', async (unit: Unit) => {
            this.unit = unit;
            await this.buildFormAsync();
        });

        this._bwctHubService.unit$.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (unit: Unit) => {
            this.unit = unit;
            if (this.unit.unitSettings) {
                await this.buildFormAsync();
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // ngOnDestroy
    // -----------------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
